import Vue from "vue";
import Vuex from "vuex";
// Vuexストアを永続化する
import createPersistedState from "vuex-persistedstate";
// 永続化対象のVuexストア情報を暗号化する
import { EncryptStorage } from "encrypt-storage";
import { STORE_ACTIONS } from "@/utils/config";

// ストレージを暗号化するオブジェクト
const es = new EncryptStorage("smart-pole-monitoring", {
  storageType: "sessionStorage"
});

Vue.use(Vuex);

export default new Vuex.Store({
  /**
   * Vuex ステート
   */
  state: {
    userInfo: {
      user: undefined,
      movieToken: undefined
    },

    position: {
      poleId: undefined,
      posInfo: []
    },
    selectArea: {
      areaId: undefined,
      latlng: undefined,
      name: undefined
    },
    selectPole: {
      poleId: undefined,
      areaId: undefined,
      latlng: undefined,
      address: undefined,
      sensorList: []
    },
    selectAccident: {
      accidentId: undefined,
      poleId: undefined,
      latlng: undefined,
      location: undefined,
      accidentTime: undefined,
      acceleration: undefined,
      movieList: []
    },
    // 集計画面用データ
    aggregations: {
      // 時間別
      daily: {
        date: null,
        nearMissReportList: [],
        trafficReportList: [],
        axlApproachReportList: []
      },
      // 日別
      weekly: {
        date: null,
        nearMissReportList: [],
        trafficReportList: [],
        axlApproachReportList: []
      },
      // 月別
      yearly: {
        date: null,
        nearMissReportList: [],
        trafficReportList: [],
        axlApproachReportList: []
      }
    },
    // 集計画面モード
    aggreMode: {
      // 表示対象
      category: 0,
      // 表示期間
      span: 0
    },
    // 集計フィルタ
    aggreFilters: {
      // 優先物標
      priorityObj: [],
      // 非優先物標
      nonPriorityObj: [],
      // 優先物標に対する方路IN
      priorityDirIn: [],
      // 非優先物標に対する方路IN
      nonPriorityDirIn: [],
      // 優先物標に対する方路OUT
      priorityDirOut: [],
      // 非優先物標に対する方路OUT
      nonPriorityDirOut: [],
      // 対象物標
      filteringObj: [],
      // 対象方路IN
      filteringDirIn: [],
      // 対象方路OUT
      filteringDirOut: []
    },
    areaList: [],
    poleList: [],
    poleInfo: [],
    accidentList: [],
    posList: [],
    ledList: [],
    cameraList: [],

    nearMisses: [],
    accidents: [],
    parkingList: [],
    watchingList: [],
    summariesDailyList: [],
    summariesWeeklyList: [],
    nearMissDetailInfo: {},
    // ポール単位でのヒヤリハット、事故件数一覧
    nearMissSummaryList: [],
    macroAnalysisInfo: {},
    macroAnalysisDetaiInfo: {},
    macroAnalysisInfoDataForExcel: {},
    // 管理サーバ上のポール一覧
    adminPoleList: [],
    // ポール選択画面でのポール一覧
    displayPoleList: {
      areaId: 0,
      poleList: [],
      dispPoleList: []
    }
  },
  /**
   * Vuex ミューテーション
   */
  mutations: {
    setUserInfo(state, user) {
      state.userInfo.user = user;
    },
    setUserMovieToken(state, movieToken) {
      state.userInfo.movieToken = movieToken;
    },
    setSelectArea(state, { areaId, latlng, name }) {
      state.selectArea.areaId = areaId;
      state.selectArea.latlng = latlng;
      state.selectArea.name = name;
    },
    setSelectPole(state, { poleId, areaId, latlng, address, sensorList }) {
      state.selectPole.poleId = poleId;
      state.selectPole.areaId = areaId;
      state.selectPole.latlng = latlng;
      state.selectPole.address = address;
      state.selectPole.sensorList = sensorList;
    },

    setSelectAccident(state, { accidentId, poleId, accidentTime, location, latlng, acceleration, movieList }) {
      state.selectAccident.accidentId = accidentId;
      state.selectAccident.poleId = poleId;
      state.selectAccident.accidentTime = accidentTime;
      state.selectAccident.latlng = latlng;
      state.selectAccident.location = location;
      state.selectAccident.acceleration = acceleration;
      state.selectAccident.movieList = movieList;
    },
    setAreaList(state, areaList) {
      state.areaList = areaList;
    },
    setPoleList(state, poleList) {
      state.poleList = poleList;
    },
    setPoleInfo(state, poleInfo) {
      state.poleInfo = poleInfo;
    },
    setAccidentList(state, accidentList) {
      state.accidentList = accidentList;
    },
    setPosList(state, posList) {
      state.posList = posList;
    },
    setLedList(state, ledList) {
      state.ledList = ledList;
    },
    setCameraList(state, cameraList) {
      state.cameraList = cameraList;
    },
    setNearMisses(state, nearMisses) {
      state.nearMisses = nearMisses;
    },
    setAccidents(state, accidents) {
      state.accidents = accidents;
    },
    setParking(state, parkingList) {
      state.parkingList = parkingList;
    },
    setWatching(state, watchingList) {
      state.watchingList = watchingList;
    },
    setSumariesDailyList(state, summariesDailyList) {
      state.summariesDailyList = summariesDailyList;
    },
    setSumariesWeeklyList(state, summariesWeeklyList) {
      state.summariesWeeklyList = summariesWeeklyList;
    },
    setNearMissDetailInfo(state, nearMissDetailInfo) {
      state.nearMissDetailInfo = nearMissDetailInfo;
    },
    /**
     * ヒヤリハット、事故件数一覧を更新する
     * @param {Object} state Vuexステート
     * @param {Array} nearMissSummaryList ヒヤリハット、事故件数一覧
     */
    setNearMissSummaryList(state, nearMissSummaryList) {
      state.nearMissSummaryList = nearMissSummaryList;
    },
    setMacroAnalysisInfo(state, macroAnalysisInfo) {
      state.macroAnalysisInfo = macroAnalysisInfo;
    },
    setMacroAnalysisDetaiInfo(state, macroAnalysisDetaiInfo) {
      state.macroAnalysisDetaiInfo = macroAnalysisDetaiInfo;
    },
    setMacroAnalysisInfoDataForExcel(state, macroAnalysisInfoDataForExcel) {
      state.macroAnalysisInfoDataForExcel = macroAnalysisInfoDataForExcel;
    },
    /**
     * 管理サーバ上のポール一覧を設定する
     * @param {Object} state Vuexステート
     * @param {Array} adminPoleList 管理サーバー上のポール一覧
     */
    setAdminPoleList(state, adminPoleList) {
      state.adminPoleList = adminPoleList;
    },
    /**
     * ポール選択画面でのポール一覧データを設定する
     * @param {Object} state Vuexステート
     * @param {Object} displayPoleList ポール一覧
     */
    setDisplayPoleList(state, displayPoleList) {
      state.displayPoleList = displayPoleList;
    },
    setDailyDate(state, date) {
      state.aggregations.daily.date = date;
    },
    /**
     * 日次のヒヤリハット件数推移を設定する
     * @param {Object} state Vuexステート
     * @param {Array} list ヒヤリハット件数推移
     */
    setDailyNearMissReportList(state, list) {
      state.aggregations.daily.nearMissReportList = list;
    },
    /**
     * 日次の物標通過台数推移を設定する
     * @param {Object} state Vuexステート
     * @param {Array} list 物標通過台数推移
     */
    setDailyTrafficReportList(state, list) {
      state.aggregations.daily.trafficReportList = list;
    },
    /**
     * 日次の通過速度推移を設定する
     * @param {Object} state Vuexステート
     * @param {Array} list 通過速度推移
     */
    setDailyAxlApproachReportList(state, list) {
      state.aggregations.daily.axlApproachReportList = list;
    },
    setWeeklyDate(state, date) {
      state.aggregations.weekly.date = date;
    },
    /**
     * 週次のヒヤリハット件数推移を設定する
     * @param {Object} state Vuexステート
     * @param {Array} list ヒヤリハット件数推移
     */
    setWeeklyNearMissReportList(state, list) {
      state.aggregations.weekly.nearMissReportList = list;
    },
    /**
     * 週次の物標通過台数推移を設定する
     * @param {Object} state Vuexステート
     * @param {Array} list 物標通過台数推移
     */
    setWeeklyTrafficReportList(state, list) {
      state.aggregations.weekly.trafficReportList = list;
    },
    /**
     * 週次の通過速度推移を設定する
     * @param {Object} state Vuexステート
     * @param {Array} list 通過速度推移
     */
    setWeeklyAxlApproachReportList(state, list) {
      state.aggregations.weekly.axlApproachReportList = list;
    },
    setYearlyDate(state, date) {
      state.aggregations.yearly.date = date;
    },
    /**
     * 週次のヒヤリハット件数推移を設定する
     * @param {Object} state Vuexステート
     * @param {Array} list ヒヤリハット件数推移
     */
    setYearlyNearMissReportList(state, list) {
      state.aggregations.yearly.nearMissReportList = list;
    },
    /**
     * 週次の物標通過台数推移を設定する
     * @param {Object} state Vuexステート
     * @param {Array} list 物標通過台数推移
     */
    setYearlyTrafficReportList(state, list) {
      state.aggregations.yearly.trafficReportList = list;
    },
    /**
     * 週次の通過速度推移を設定する
     * @param {Object} state Vuexステート
     * @param {Array} list 通過速度推移
     */
    setYearlyAxlApproachReportList(state, list) {
      state.aggregations.yearly.axlApproachReportList = list;
    },
    /**
     * 集計画面モードの値を設定する
     * @param {Object} state Vuexステート
     * @param {Object} mode 集計画面モード
     */
    setAggreMode(state, mode) {
      state.aggreMode = mode;
    },
    setPriorityObj(state, obj) {
      state.aggreFilters.priorityObj = obj;
    },
    setNonPriorityObj(state, obj) {
      state.aggreFilters.nonPriorityObj = obj;
    },
    setFilteringObj(state, obj) {
      state.aggreFilters.filteringObj = obj;
    },
    setPriorityDirIn(state, dir) {
      state.aggreFilters.priorityDirIn = dir;
    },
    setPriorityDirOut(state, dir) {
      state.aggreFilters.priorityDirOut = dir;
    },
    setNonPriorityDirIn(state, dir) {
      state.aggreFilters.nonPriorityDirIn = dir;
    },
    setNonPriorityDirOut(state, dir) {
      state.aggreFilters.nonPriorityDirOut = dir;
    },
    setFilteringDirIn(state, dir) {
      state.aggreFilters.filteringDirIn = dir;
    },
    setFilteringDirOut(state, dir) {
      state.aggreFilters.filteringDirOut = dir;
    }
  },
  /**
   * Vuexアクション
   */
  actions: {
    setUserInfo({ commit }, data) {
      commit(STORE_ACTIONS.setUserInfo, data);
    },
    setUserMovieToken({ commit }, data) {
      commit(STORE_ACTIONS.setUserMovieToken, data);
    },
    setSelectArea({ commit }, data) {
      commit(STORE_ACTIONS.setSelectArea, data);
    },
    setSelectPole({ commit }, data) {
      commit(STORE_ACTIONS.setSelectPole, data);
    },
    setSelectAccident({ commit }, data) {
      commit(STORE_ACTIONS.setSelectAccident, data);
    },
    setAreaList({ commit }, areaList) {
      commit(STORE_ACTIONS.setAreaList, areaList);
    },
    setPoleList({ commit }, poleList) {
      commit(STORE_ACTIONS.setPoleList, poleList);
    },
    setPoleInfo({ commit }, poleInfo) {
      commit(STORE_ACTIONS.setPoleInfo, poleInfo);
    },
    setLedList({ commit }, ledList) {
      commit(STORE_ACTIONS.setLedList, ledList);
    },
    setAccidentList({ commit }, accidentList) {
      commit(STORE_ACTIONS.setAccidentList, accidentList);
    },
    setPosList({ commit }, posList) {
      commit(STORE_ACTIONS.setPosList, posList);
    },
    setCameraList({ commit }, cameraList) {
      commit(STORE_ACTIONS.setCameraList, cameraList);
    },
    setNearMisses({ commit }, nearMisses) {
      commit(STORE_ACTIONS.setNearMisses, nearMisses);
    },
    setAccidents({ commit }, accidents) {
      commit(STORE_ACTIONS.setAccidents, accidents);
    },
    setParking({ commit }, parkingList) {
      commit(STORE_ACTIONS.setParking, parkingList);
    },
    setWatching({ commit }, watchingList) {
      commit(STORE_ACTIONS.setWatching, watchingList);
    },
    setSumariesDailyList({ commit }, summariesDailyList) {
      commit(STORE_ACTIONS.setSumariesDailyList, summariesDailyList);
    },
    setSumariesWeeklyList({ commit }, summariesWeeklyList) {
      commit(STORE_ACTIONS.setSumariesWeeklyList, summariesWeeklyList);
    },
    setNearMissDetailInfo({ commit }, nearMissDetailInfo) {
      commit(STORE_ACTIONS.setNearMissDetailInfo, nearMissDetailInfo);
    },
    /**
     * Vuexミューテーション上の更新処理を実行する
     * @param {Object} commit ミューテーションのコミット処理
     * @param {Array} nearMissSummaryList ヒヤリハット、事故件数一覧
     */
    setNearMissSummaryList({ commit }, nearMissSummaryList) {
      if (nearMissSummaryList !== void 0) {
        commit(STORE_ACTIONS.setNearMissSummaryList, nearMissSummaryList);
      }
    },
    setMacroAnalysisInfo({ commit }, macroAnalysisInfo) {
      commit(STORE_ACTIONS.setMacroAnalysisInfo, macroAnalysisInfo);
    },
    setMacroAnalysisDetaiInfo({ commit }, macroAnalysisDetaiInfo) {
      commit(STORE_ACTIONS.setMacroAnalysisDetaiInfo, macroAnalysisDetaiInfo);
    },
    setMacroAnalysisInfoDataForExcel({ commit }, macroAnalysisInfoDataForExcel) {
      commit(STORE_ACTIONS.setMacroAnalysisInfoDataForExcel, macroAnalysisInfoDataForExcel);
    },
    /**
     * 管理サーバ上のポール一覧をストアに更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} adminPoleList ポール一覧
     */
    updateAdminPoleList({ commit }, adminPoleList) {
      if (adminPoleList !== void 0 && adminPoleList !== null) {
        commit("setAdminPoleList", adminPoleList);
      }
    },
    updateDailyDate({ commit }, date) {
      if (date !== void 0 && date !== null) {
        commit("setDailyDate", date);
      }
    },
    /**
     * 日次のヒヤリハット件数推移を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} list ヒヤリハット件数推移
     */
    updateDailyNearMissReportList({ commit }, list) {
      if (list !== void 0 && list !== null) {
        commit("setDailyNearMissReportList", list);
      }
    },
    /**
     * 日次の物標通過件数推移を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} list 物標通過件数推移
     */
    updateDailyTrafficReportList({ commit }, list) {
      if (list !== void 0 && list !== null) {
        commit("setDailyTrafficReportList", list);
      }
    },
    /**
     * 日次の通過速度推移を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} list 通過速度推移
     */
    updateDailyAxlApproarchReportList({ commit }, list) {
      if (list !== void 0 && list !== null) {
        commit("setDailyAxlApproachReportList", list);
      }
    },
    /**
     * @param {Object} commit コミットオブジェクト
     * @param {Object} date 日付
     */
    updateWeeklyDate({ commit }, date) {
      if (date !== void 0 && date !== null) {
        commit("setWeeklyDate", date);
      }
    },
    /**
     * 週次のヒヤリハット件数推移を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} list ヒヤリハット件数推移
     */
    updateWeeklyNearMissReportList({ commit }, list) {
      if (list !== void 0 && list !== null) {
        commit("setWeeklyNearMissReportList", list);
      }
    },
    /**
     * 週次の物標通過件数推移を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} list 物標通過件数推移
     */
    updateWeeklyTrafficReportList({ commit }, list) {
      if (list !== void 0 && list !== null) {
        commit("setWeeklyTrafficReportList", list);
      }
    },
    /**
     * 週次の通過速度推移を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} list 通過速度推移
     */
    updateWeeklyAxlApproarchReportList({ commit }, list) {
      if (list !== void 0 && list !== null) {
        commit("setWeeklyAxlApproachReportList", list);
      }
    },
    updateYearlyDate({ commit }, date) {
      if (date !== void 0 && date !== null) {
        commit("setYearlyDate", date);
      }
    },
    /**
     * 週次のヒヤリハット件数推移を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} list ヒヤリハット件数推移
     */
    updateYearlyNearMissReportList({ commit }, list) {
      if (list !== void 0 && list !== null) {
        commit("setYearlyNearMissReportList", list);
      }
    },
    /**
     * 週次の物標通過件数推移を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} list 物標通過件数推移
     */
    updateYearlyTrafficReportList({ commit }, list) {
      if (list !== void 0 && list !== null) {
        commit("setYearlyTrafficReportList", list);
      }
    },
    /**
     * 週次の通過速度推移を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Array} list 通過速度推移
     */
    updateYearlyAxlApproarchReportList({ commit }, list) {
      if (list !== void 0 && list !== null) {
        commit("setYearlyAxlApproachReportList", list);
      }
    },
    /**
     * 集計画面モードを更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Object} mode 表示対象モード
     */
    updateAggreMode({ commit }, mode) {
      if (mode.category !== void 0 && mode.category !== null && typeof mode.category === "number") {
        if (mode.span !== void 0 && mode.span !== null && typeof mode.span === "number") {
          commit("setAggreMode", mode);
        }
      }
    },
    // 集計フィルタの更新
    /**
     * a
     * @param {Object} commit コミットオブジェクト
     * @param {String[]} obj 物標一覧
     */
    updatePriorityObj({ commit }, obj) {
      if (obj !== void 0 && obj !== null) {
        commit("setPriorityObj", obj);
      }
    },
    /**
     * a
     * @param {Object} commit コミットオブジェクト
     * @param {String[]} obj 物標一覧
     */
    updateNonPriorityObj({ commit }, obj) {
      if (obj !== void 0 && obj !== null) {
        commit("setNonPriorityObj", obj);
      }
    },
    /**
     * a
     * @param {Object} commit コミットオブジェクト
     * @param {String[]} obj 物標一覧
     */
    updateFilteringObj({ commit }, obj) {
      if (obj !== void 0 && obj !== null) {
        commit("setFilteringObj", obj);
      }
    },
    /**
     * a
     * @param {Object} commit コミットオブジェクト
     * @param {String[]} dir 方路一覧
     */
    updatePriorityDirIn({ commit }, dir) {
      if (dir !== void 0 && dir !== null) {
        commit("setPriorityDirIn", dir);
      }
    },
    /**
     * a
     * @param {Object} commit コミットオブジェクト
     * @param {String[]} dir 方路一覧
     */
    updatePriorityDirOut({ commit }, dir) {
      if (dir !== void 0 && dir !== null) {
        commit("setPriorityDirOut", dir);
      }
    },
    /**
     * a
     * @param {Object} commit コミットオブジェクト
     * @param {String[]} dir 方路一覧
     */
    updateNonPriorityDirIn({ commit }, dir) {
      if (dir !== void 0 && dir !== null) {
        commit("setNonPriorityDirIn", dir);
      }
    },
    /**
     * a
     * @param {Object} commit コミットオブジェクト
     * @param {String[]} dir 方路一覧
     */
    updateNonPriorityDirOut({ commit }, dir) {
      if (dir !== void 0 && dir !== null) {
        commit("setNonPriorityDirOut", dir);
      }
    },
    /**
     * a
     * @param {Object} commit コミットオブジェクト
     * @param {String[]} dir 方路一覧
     */
    updateFilteringDirIn({ commit }, dir) {
      if (dir !== void 0 && dir !== null) {
        commit("setFilteringDirIn", dir);
      }
    },
    /**
     * a
     * @param {Object} commit コミットオブジェクト
     * @param {String[]} dir 方路一覧
     */
    updateFilteringDirOut({ commit }, dir) {
      if (dir !== void 0 && dir !== null) {
        commit("setFilteringDirOut", dir);
      }
    },
    /**
     * ポール選択画面で使用するポール一覧情報を更新する
     * @param {Object} commit コミットオブジェクト
     * @param {Object} displayPoleList ポール一覧
     */
    setDisplayPoleList({ commit }, displayPoleList) {
      // パラメータの内容に未定義箇所がなければ更新を実施する
      if (displayPoleList !== void 0 && displayPoleList !== null) {
        if (displayPoleList.areaId !== void 0 && displayPoleList.areaId !== null) {
          if (displayPoleList.poleList !== void 0 && displayPoleList.dispPoleList !== void 0) {
            commit(STORE_ACTIONS.setDisplayPoleList, displayPoleList);
          }
        }
      }
    }
  },
  plugins: [
    // Vuexストアの値をローカルストレージに永続化
    createPersistedState({
      // ローカルストレージ上のキーを設定
      key: "startPoleMonitoring",
      overwrite: true,
      storage: {
        // 永続化するVuexストアの値を暗号化して、外部から閲覧できないようにする
        getItem: key => es.getItem(key),
        setItem: (key, value) => es.setItem(key, value),
        removeItem: key => es.removeItem(key)
      }
    })
  ]
});
