<template>
  <div>
    <Loading v-show="isLoading" />
    <v-row class="ml-2 mr-2">
      <v-col cols="12">
        <Bar Title="見守り画面" />
      </v-col>
    </v-row>

    <v-row class="ml-2 mr-2">
      <v-col cols="7" id="left">
        <v-card elevation="10">
          <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 8vh">
            <span class="py-0">VIRTUAL映像</span>
          </v-card-title>

          <div class="dataScreen cols-7">
            <div id="monitorMap"></div>
          </div>
        </v-card>
      </v-col>

      <v-col cols="5" id="right">
        <div class="mb-2">
          <v-card elevation="3">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 8vh">
              <span class="py-0">日付(表示範囲)</span>
            </v-card-title>
            <Form>
              <template v-slot:dateTime>
                <div class="d-flex">
                  <DatePicker :value="searchConditions.fromDate" @input="searchConditions.fromDate = $event" />
                  <TimePicker :value="searchConditions.fromTime" @input="searchConditions.fromTime = $event" />
                </div>
                <p class="pt-3">～</p>
                <div class="d-flex">
                  <DatePicker :value="searchConditions.toDate" @input="searchConditions.toDate = $event" />
                  <TimePicker :value="searchConditions.toTime" @input="searchConditions.toTime = $event" />
                </div>
              </template>

              <template v-slot:button>
                <Button label="決定" @click="Search()" />
              </template>
            </Form>
          </v-card>
        </div>

        <div class="mb-2">
          <v-card v-if="editingData.idList" elevation="3">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 8vh">
              <span class="py-0">ID</span>
            </v-card-title>
            <div v-for="(item, index) in editingData.idList" :key="index">
              <v-checkbox v-model="searchConditions.targetvehicleId" :value="item" :label="item" @change="showInfoByVehicleId((filter = true))" />
            </div>
          </v-card>
        </div>

        <div class="mb-2">
          <v-card elevation="3">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 8vh">
              <span class="py-0">行動履歴</span>
            </v-card-title>
            <v-data-table :headers="actionHistoryTableHeader" :items="editingData.actionHistoryList"> </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <Modal :value="error" @input="error = $event" :text="errorText" />
    <Title :titleInfo="titleInfo" />
  </div>
</template>

<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import "leaflet-arrowheads";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import { getInitFromDate, getInitToDate } from "@/utils/day.js";
  import { commonFunction, communicationFunction } from "@/mixins/utils";
  import {
    poleIcon,
    blueIcon,
    redIcon,
    greenIcon,
    yellowIcon,
    lightblueIcon,
    pinkIcon,
    lightgreenIcon,
    orangeIcon,
    blackIcon,
    purpleIcon,
    wineIcon,
    blueTIcon,
    redTIcon,
    greenTIcon,
    yellowTIcon,
    lightblueTIcon,
    pinkTIcon,
    lightgreenTIcon,
    orangeTIcon,
    blackTIcon,
    purpleTIcon,
    wineTIcon,
    blueSIcon,
    redSIcon,
    greenSIcon,
    yellowSIcon,
    lightblueSIcon,
    pinkSIcon,
    lightgreenSIcon,
    orangeSIcon,
    blackSIcon,
    purpleSIcon,
    wineSIcon
  } from "@/utils/mapIcon.js";

  export default {
    components: {
      Title,
      Loading
    },
    mixins: [commonFunction, communicationFunction],
    data() {
      return {
        isLoading: false,
        areaId: this.$store.state.selectArea.areaId,
        setting: undefined,
        titleInfo: {
          title: "",
          show: {
            realtime: false,
            multicamera: false,
            virtual: false,
            menu: true
          },
          menuList: []
        },
        actionHistoryTableHeader: [{ value: "detectedTime" }, { value: "vehicleId" }, { value: "status" }],

        iconList: [
          blueIcon,
          redIcon,
          greenIcon,
          yellowIcon,
          lightblueIcon,
          pinkIcon,
          lightgreenIcon,
          orangeIcon,
          blackIcon,
          purpleIcon,
          wineIcon,
          blueTIcon,
          redTIcon,
          greenTIcon,
          yellowTIcon,
          lightblueTIcon,
          pinkTIcon,
          lightgreenTIcon,
          orangeTIcon,
          blackTIcon,
          purpleTIcon,
          wineTIcon,
          blueSIcon,
          redSIcon,
          greenSIcon,
          yellowSIcon,
          lightblueSIcon,
          pinkSIcon,
          lightgreenSIcon,
          orangeSIcon,
          blackSIcon,
          purpleSIcon,
          wineSIcon
        ],

        lineColors: [
          "#3d46cd",
          "#ed161f",
          "#1cb24b",
          "#f7f700",
          "#99d9ea",
          "#ffaec9",
          "#b6e717",
          "#ff7f27",
          "#000000",
          "#a447a5",
          "#880015",
          "#3d46cd",
          "#ed161f",
          "#1cb24b",
          "#f7f700",
          "#99d9ea",
          "#ffaec9",
          "#b6e717",
          "#ff7f27",
          "#000000",
          "#a447a5",
          "#880015",
          "#3d46cd",
          "#ed161f",
          "#1cb24b",
          "#f7f700",
          "#99d9ea",
          "#ffaec9",
          "#b6e717",
          "#ff7f27",
          "#000000",
          "#a447a5",
          "#880015"
        ],

        idIconColors: [],

        searchConditions: {
          fromDate: "",
          toDate: "",
          fromTime: "",
          toTime: "",
          targetvehicleId: []
        },

        editingData: {
          areaName: "",
          mapLatlng: this.$store.state.selectArea.latlng,
          sensorList: [],
          poleMarkers: [],
          markerLatlng: [0, 0],
          idList: [],
          actionHistories: [],
          actionHistoryList: [],
          targetVehicleList: [],
          poleInfoList: []
        },

        map: {},
        error: false,
        errorText: "データ",
        filter: false,
        polePoint: []
      };
    },

    created() {
      this.initialize();
    },

    async mounted() {
      await this.setPoleList(this.areaId);
      await this.Search();
    },

    methods: {
      initialize() {
        this.setting = this.getSetting();
        this.titleInfo.menuList = this.setting.menu.watch;
        const from = getInitFromDate();
        const to = getInitToDate();
        this.searchConditions.fromDate = from[0];
        this.searchConditions.fromTime = from[1];
        this.searchConditions.toDate = to[0];
        this.searchConditions.toTime = to[1];
      },

      async setPoleList(areaId) {
        this.editingData.sensorList = [];
        await this.updatePoleListAsAreaId(areaId);
        let list = this.$store.state.poleList;
        for (let i = 0; i < list.length; i++) {
          this.editingData.sensorList.push({
            poleId: list[i].poleId,
            latlng: [list[i].latitude, list[i].longitude]
          });
        }
      },

      checkNull(data) {
        let checkData = data;

        for (let i = 0; i < this.editingData.idList.length; i++) {
          const key = this.editingData.idList[i];
          for (let j = 0; j < checkData[key].length; j++) {
            if (checkData[key][j].poleId === null) {
              checkData[key][j].poleId = 0;
            }

            if (checkData[key][j].latitude === null) {
              checkData[key][j].latitude = 0;
            }

            if (checkData[key][j].longitude === null) {
              checkData[key][j].longitude = 0;
            }

            if (checkData[key][j].vehicleStat === null) {
              checkData[key][j].vehicleStat = 2;
            }

            if (checkData[key][j].detectedTime === null) {
              checkData[key][j].detectedTime = "データなし";
            }
          }
        }
      },

      async Search() {
        this.editingData.actionHistories = [];
        this.editingData.actionHistoryList = [];
        this.editingData.poleInfoList = [];
        const from = this.searchConditions.fromDate + " " + this.searchConditions.fromTime;
        const to = this.searchConditions.toDate + " " + this.searchConditions.toTime;
        this.isLoading = true;
        let promise = this.updateWatchingData(this.areaId, from.replaceAll("-", "/"), to.replaceAll("-", "/"));
        promise
          .then(() => {
            this.isLoading = false;
            this.editingData.actionHistories = this.$store.state.watchingList;
            this.editingData.idList = Object.keys(this.editingData.actionHistories);
            this.checkNull(this.editingData.actionHistories);
            //idListからアイコンカラー情報を加えた配列を新たに作成
            let i = 0;
            this.editingData.idList.forEach(id => {
              this.idIconColors[id] = this.iconList[i & 31];
              i++;
            });
            this.editingData.poleMarkers = this.editingData.sensorList.latlng;
          })
          .then(() => {
            this.showInfoByVehicleId((this.filter = true));
          })
          .then(() => {
            let h = document.getElementById("right").clientHeight - document.documentElement.clientHeight * 0.08 - 24 + "px";
            document.getElementById("monitorMap").style.height = h;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },

      setMonitorMap(data) {
        let latlng = this.$store.state.selectArea.latlng;
        let zoom = this.setting.selectPole.zoom.default;
        let zoom_min = this.setting.virtualMap.zoom.min;
        let zoom_max = this.setting.virtualMap.zoom.max;

        if (Object.keys(this.map).length === 0) {
          const map = L.map("monitorMap", {
            dragging: true,
            touchZoom: true,
            scrollWheelZoom: true,
            doubleClickZoom: true,
            boxZoom: true,
            tap: true,
            keyboard: true,
            zoomControl: true,
            minZoom: zoom_min,
            maxZoom: zoom_max
          }).setView(latlng, zoom);
          this.map = map;
        } else {
          this.map.remove();
          const map = L.map("monitorMap", {
            dragging: true,
            touchZoom: true,
            scrollWheelZoom: true,
            doubleClickZoom: true,
            boxZoom: true,
            tap: true,
            keyboard: true,
            zoomControl: true,
            minZoom: zoom_min,
            maxZoom: zoom_max
          }).setView(latlng, zoom);
          this.map = map;
        }

        L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: 'Map data © <a href="http://openstreetmap.org/">OpenStreetMap</a>',
          maxZoom: 18
        }).addTo(this.map);

        let polePoint = "";
        let bindPopup = "";
        let list = "";

        if (this.editingData.sensorList.length !== 0) {
          for (let i = 0; i < this.editingData.sensorList.length; i++) {
            this.editingData.poleMarkers = this.editingData.sensorList[i].latlng;
            polePoint = L.marker(this.editingData.poleMarkers, { icon: poleIcon });
            if (this.editingData.poleInfoList.length !== 0) {
              bindPopup = document.createElement("ul");
              for (let j = 0; j < this.editingData.poleInfoList.length; j++) {
                if (this.editingData.sensorList[i].poleId === this.editingData.poleInfoList[j].poleId) {
                  list = document.createElement("li");
                  list.textContent = `${this.editingData.poleInfoList[j].vehicleId} 
														${this.editingData.poleInfoList[j].detectedTime} 
															${this.editingData.poleInfoList[j].status}`;
                  bindPopup.appendChild(list);
                }
              }
              if (bindPopup.innerText !== "") {
                polePoint.bindPopup(bindPopup);
              } else {
                polePoint.bindPopup(`接近情報はありません`);
              }
            } else {
              polePoint.bindPopup(`接近情報はありません`);
            }
            polePoint.addTo(this.map);
          }
        }

        if (this.editingData.actionHistories) {
          let key = "";
          let targetVehiclePoint = [];
          for (let i = 0; i < this.editingData.idList.length; i++) {
            key = this.editingData.idList[i];
            if (data[key]) {
              for (let j = 0; j < data[key].length; j++) {
                targetVehiclePoint.push([data[key][j].latitude, data[key][j].longitude]);
                L.marker([data[key][j].latitude, data[key][j].longitude], { icon: this.idIconColors[key] }).addTo(this.map);
              }
              L.polyline(targetVehiclePoint, {
                color: this.lineColors[i & 31],
                weight: 3,
                dashArray: "10, 10",
                dashOffset: 1
              })
                .arrowheads({ size: "20px", fill: true })
                .addTo(this.map);
            }
            targetVehiclePoint = [];
          }
        }
        let h = document.getElementById("right").clientHeight - document.documentElement.clientHeight * 0.08 - 24 + "px";
        document.getElementById("monitorMap").style.height = h;
      },

      setActionHistoryList(actionHistoryList) {
        this.editingData.actionHistoryList = [];
        this.editingData.poleInfoList = [];
        const targetStatus = {
          0: "から 離脱",
          1: "付近 接近",
          2: "へのデータなし"
        };
        const status = {
          0: "離脱",
          1: "接近",
          2: "データなし"
        };

        Object.keys(actionHistoryList).forEach(key => {
          actionHistoryList[key].forEach(data => {
            const tableData = {
              detectedTime: data.detectedTime,
              vehicleId: key,
              status: `ポールID${data.poleId}${targetStatus[data.vehicleStat]}`
            };

            const poleInfo = {
              vehicleId: key,
              poleId: data.poleId,
              detectedTime: data.detectedTime,
              status: `${status[data.vehicleStat]}`
            };

            this.editingData.actionHistoryList.push(tableData);
            this.editingData.poleInfoList.push(poleInfo);
          });
        });
        this.editingData.actionHistoryList.sort(function(a, b) {
          if (a.detectedTime !== b.detectedTime) {
            return new Date(b.detectedTime) - new Date(a.detectedTime);
          }
          if (a.vehicleId !== b.vehicleId) {
            return (b.vehicleId - a.vehicleId) * -1;
          }
          return 0;
        });
      },

      showInfoByVehicleId(filter) {
        if (!filter) return;
        this.editingData.targetVehicleList = [];
        const filterList = {};
        for (let i = 0; i < this.searchConditions.targetvehicleId.length; i++) {
          const key = this.searchConditions.targetvehicleId[i];
          if (this.editingData.actionHistories[key]) {
            filterList[key] = [];
            filterList[key] = this.editingData.actionHistories[key];
          }
        }
        this.editingData.targetVehicleList.push(filterList);
        this.setMonitorMap(this.editingData.targetVehicleList[0]);
        this.setActionHistoryList(this.editingData.targetVehicleList[0]);
      }
    }
  };
</script>

<style>
  #monitorMap {
    z-index: 0;
    height: 500px;
    width: 100%;
    margin: 0 auto;
  }
  .v-input--selection-controls {
    margin-top: 1px;
    padding-top: 1px;
  }
</style>
