<template>
  <v-dialog v-model="dialog.isDialog" width="300px" persistent>
    <v-card>
      <v-container>
        <v-card-title id="dialog_title" nav dense> ポールデータ選択 </v-card-title>
        <v-divider />
        <v-card-subtitle id="dialog_subtitle" nav dense>
          <span v-text="dialog.name" />
        </v-card-subtitle>
        <v-card-actions>
          <v-row justify="center">
            <v-col cols="0">
              <v-btn color="blue-grey" dark rounded @click="realtimeData">現在データを見る</v-btn>
              <p />
              <v-btn color="blue-grey" dark rounded @click="historyData">過去データを見る</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey darken-2" dark rounded @click="dialogClose">Close</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import router from "@/router";
  import { commonFunction } from "@/mixins/utils";

  export default {
    name: "DataSelectDialog",
    props: ["dialog"],
    mixins: [commonFunction],
    methods: {
      realtimeData() {
        router.push({ path: this.getRouterPath("/monitor/realtime") });
      },
      historyData() {
        router.push({ path: this.getRouterPath("/monitor/historylist"), query: { direct: false } });
      },
      dialogClose() {
        this.$emit("dialogClose");
      }
    }
  };
</script>

<style>
  #dialog_title {
    height: 5vh;
    font-size: 18px;
    font-weight: bold;
  }
  #dialog_subtitle {
    height: 5vh;
    font-size: 15px;
    font-weight: bold;
  }
</style>
