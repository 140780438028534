<template>
  <div class="selectpole_view">
    <v-container fluid fill-height>
      <SelectPole id="selectpole_map" ref="map" :pointList="titleInfo.pointList" v-on:poleSelected="poleSelected" v-on:poleListCreated="poleListCreated"></SelectPole>
    </v-container>
    <DataSelectDialog :dialog="dialog" v-on:dialogClose="dialogClose" />

    <v-main>
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" v-on:selectPoint="selectPoint" v-on:enterPoint="enterPoint" />
    <Loading v-show="isLoading" />
  </div>
</template>

<script>
  import SelectPole from "@/components/SelectPole";
  import DataSelectDialog from "@/components/DataSelectDialog";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import { commonFunction } from "@/mixins/utils";

  export default {
    name: "SelectPoleView",
    components: {
      SelectPole,
      Title,
      Loading,
      DataSelectDialog
    },
    mixins: [commonFunction],
    data: function() {
      return {
        dialog: {
          isDialog: false,
          name: ""
        },
        titleInfo: {
          title: undefined,
          show: {
            point: true,
            menu: true
          },
          pointList: undefined,
          menuList: undefined
        },
        isLoading: true
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      initialize() {
        this.setting = this.getSetting();
        this.titleInfo.title = this.$store.state.selectArea.name + "地域 検証名";
        this.titleInfo.menuList = this.setting.menu.selectPole;
      },
      selectPoint(data) {
        this.$refs.map.setCenter(data);
      },
      enterPoint(data) {
        this.$refs.map.enterPoint(data);
      },
      createPointList(poleList) {
        let pointList = [];
        poleList.forEach(pole => {
          pointList.push({
            id: pole.poleId,
            name: pole.address,
            latlng: pole.latlng
          });
        });
        return pointList;
      },
      poleListCreated(poleList) {
        this.titleInfo.pointList = this.createPointList(poleList);
        this.isLoading = false;
      },
      poleSelected(title, poleId) {
        this.setSelectPole(poleId, title);
        this.dialog.name = title;
        this.dialog.isDialog = true;
      },
      dialogClose() {
        this.dialog.isDialog = false;
      }
    }
  };
</script>

<style>
  #selectpole_map {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
