<template>
  <div class="title">
    <v-app-bar app color="#0041c0" dark nav dense>
      <span class="display-0 white--text">
        <span v-text="titleInfo.title" />
      </span>
      <v-spacer />
      <v-spacer />
      <v-btn v-if="titleInfo.show.realtime == true" v-on:click="onClickRealtime" text>
        <span>REALTIME</span>
        <v-icon>mdi-arrange-send-backward</v-icon>
      </v-btn>
      <v-btn v-if="titleInfo.show.virtual == true" v-on:click="onClickVirtual" text>
        <span>VIRTUAL</span>
        <v-icon>mdi-arrange-send-backward</v-icon>
      </v-btn>
      <v-btn v-if="titleInfo.show.multicamera == true" v-on:click="onClickMultiCamera" text>
        <span>MULTI CAMERA</span>
        <v-icon>mdi-arrange-send-backward</v-icon>
      </v-btn>
      <v-btn v-if="titleInfo.show.back == true" v-on:click="onClickBack" text>
        <span>前に戻る</span>
        <v-icon>mdi-arrange-send-backward</v-icon>
      </v-btn>

      <v-app-bar-nav-icon v-if="titleInfo.show.point == true && isPoint == false" @click="onClickPoint">
        <v-icon>mdi-view-list</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon class="blue--text" v-if="titleInfo.show.point == true && isPoint == true" @click="onClickPoint">
        <v-icon>mdi-view-list</v-icon>
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon v-if="titleInfo.show.location == true && isLocation == false" @click="onClickLocation">
        <v-icon>mdi-view-list</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon class="blue--text" v-if="titleInfo.show.location == true && isLocation == true" @click="onClickLocation">
        <v-icon>mdi-view-list</v-icon>
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon v-if="titleInfo.show.menu == true && isMenu == false" @click="onClickMenu">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon class="blue--text" v-if="titleInfo.show.menu == true && isMenu == true" @click="onClickMenu">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="isPoint" absolute right dark width="350">
      <ListTable :pointList="titleInfo.pointList" v-on:selectPoint="selectPoint" v-on:enterPoint="enterPoint" />
    </v-navigation-drawer>

    <v-navigation-drawer v-if="titleInfo.show.location == true" v-model="isLocation" app right dark width="350">
      <LocationListTable :locationList="titleInfo.locationList" />
    </v-navigation-drawer>

    <v-navigation-drawer v-model="isMenu" absolute right dark width="350" temporary>
      <MenuTable :menuList="titleInfo.menuList" v-on:selectMenu="selectMenu" />
    </v-navigation-drawer>

    <v-main>
      <router-view />
      <Logout ref="logout"></Logout>
    </v-main>
  </div>
</template>

<script>
  import { Auth } from "aws-amplify";
  import router from "@/router";
  import Logout from "@/components/Logout";
  import ListTable from "@/components/ListTable";
  import MenuTable from "@/components/MenuTable";
  import LocationListTable from "@/components/LocationListTable";
  import { commonFunction } from "@/mixins/utils";
  import routePath from "@/assets/setting/routingPath.json";

  export default {
    name: "Title",
    components: {
      Logout,
      ListTable,
      LocationListTable,
      MenuTable
    },
    mixins: [commonFunction],
    props: {
      titleInfo: {
        type: Object,
        require: true
      }
    },
    data() {
      return {
        setting: undefined,
        isPoint: false,
        isLocation: false,
        isMenu: false,
        areaId: this.$route.query.areaId,
        poleId: undefined
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      initialize() {
        this.setting = this.getSetting();
      },
      async onClickPoint() {
        this.isPoint = !this.isPoint;
        if (this.isPoint) {
          this.isLocation = false;
          this.isMenu = false;
        }
      },
      async onClickLocation() {
        this.isLocation = !this.isLocation;
        if (this.isLocation) {
          this.isPoint = false;
          this.isMenu = false;
        }
      },
      async onClickMenu() {
        this.isMenu = !this.isMenu;
        if (this.isMenu) {
          this.isPoint = false;
          this.isLocation = false;
        }
      },
      selectPoint(data) {
        this.$emit("selectPoint", data);
      },
      enterPoint(data) {
        this.$emit("enterPoint", data);
      },
      async selectMenu(data) {
        this.isMenu = false;
        // ログアウト
        if (data.item == "logout") {
          if (await this.$refs.logout.open()) {
            Auth.signOut();
            this.setUserInfo(undefined);
            router.push({ path: this.getRouterPath("/") });
          }
        } else {
          // ログアウト以外
          const target = routePath.routes[data.item];
          if (target !== void 0) {
            if (target.query !== void 0) {
              router.push({ path: this.getRouterPath(target.path), query: { direct: target.query.direct } });
            } else {
              router.push({ path: this.getRouterPath(target.path) });
            }
          }
        }
        /*
        if (data.item == "selectArea") {
          router.push({ path: this.getRouterPath("/monitor/selectarea") });
        } else if (data.item == "selectPole") {
          router.push({ path: this.getRouterPath("/monitor/selectpole") });
        } else if (data.item == "accidents") {
          router.push({ path: this.getRouterPath(`/monitor/accident`) });
        } else if (data.item == "parking") {
          router.push({ path: this.getRouterPath(`/monitor/parking`) });
        } else if (data.item == "watch") {
          router.push({ path: this.getRouterPath(`/monitor/watching`) });
        } else if (data.item == "summally") {
          router.push({ path: this.getRouterPath(`/monitor/summaries`) });
        } else if (data.item == "dashboard") {
          router.push({ path: this.getRouterPath(`/monitor/dashboard`) });
        } else if (data.item == "history") {
          router.push({ path: this.getRouterPath("/monitor/historylist"), query: { direct: true } });
        } else if (data.item == "macroAnalysis") {
          router.push({ path: this.getRouterPath("/monitor/macroAnalysis") });
        } else if (data.item == "macroAnalysisInfoDataDownload") {
          router.push({ path: this.getRouterPath("/monitor/macroAnalysisInfoDataDownload") });
        } else if (data.item === "aggregation") {
          router.push({ path: this.getRouterPath("/monitor/aggregation") });
        } else if (data.item == "logout") {
          if (await this.$refs.logout.open()) {
            Auth.signOut();
            this.setUserInfo(undefined);
            router.push({ path: this.getRouterPath("/") });
          }
        }
        */
      },
      onClickMultiCamera() {
        router.push({ path: this.getRouterPath("/monitor/multicamera") });
      },
      onClickVirtual() {
        router.push({ path: this.getRouterPath("/monitor/virtual") });
      },
      onClickRealtime() {
        router.push({ path: this.getRouterPath("/monitor/realtime") });
      },
      onClickBack() {
        router.go(-1);
      }
    }
  };
</script>
