import Vue from "vue";
import VueRouter from "vue-router";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

import { Hub } from "@aws-amplify/core";

import LogInView from "@/views/LogInView";
import SelectAreaView from "@/views/monitor/SelectAreaView";
import SelectPoleView from "@/views/monitor/SelectPoleView";
import RealtimeView from "@/views/monitor/RealtimeView";
import MultiCameraView from "@/views/monitor/MultiCameraView";
import VirtualView from "@/views/monitor/VirtualView";
import HistoryListView from "@/views/monitor/HistoryListView";
import HistoryDataView from "@/views/monitor/HistoryDataView";
import settingJson from "@/assets/setting/setting";

import Accident from "@/views/monitor/Accident.vue";
import Parking from "@/views/monitor/Parking.vue";
import Watch from "@/views/monitor/Watch.vue";
import Summally from "@/views/monitor/Summally.vue";
import Dashboard from "@/views/monitor/Dashboard.vue";
import MacroAnalysis from "@/views/monitor/MacroAnalysis.vue";
import MacroAnalysisInfoDataDownload from "@/views/monitor/MacroAnalysisInfoDataDownload.vue";

import AggregationView from "@/views/monitor/AggregationView";

import UserInfo from "@/store/index";

Vue.use(VueRouter);
Vue.use(VueMaterial);

const base_url = settingJson.common.baseUrl;

Hub.listen("auth", async data => {
  switch (data.payload.event) {
    case "signIn":
      break;
    case "signOut":
      if (UserInfo.state.userInfo.user != undefined) {
        router.push({ path: base_url + "/" });
      }
      break;
  }
});

const routes = [
  {
    path: base_url + "/",
    name: "Login",
    component: LogInView
  },
  {
    path: base_url + "/monitor/selectarea",
    name: "SelectArea",
    component: SelectAreaView,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: base_url + "/monitor/selectpole",
    name: "SelectPole",
    component: SelectPoleView,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: base_url + "/monitor/realtime",
    name: "RealTime",
    component: RealtimeView,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: base_url + "/monitor/multicamera",
    name: "MultiCamera",
    component: MultiCameraView,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: base_url + "/monitor/virtual",
    name: "Virtual",
    component: VirtualView,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: base_url + "/monitor/historylist",
    name: "HistoryList",
    component: HistoryListView,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: base_url + "/monitor/historyData",
    name: "HistoryData",
    component: HistoryDataView,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: base_url + "/monitor/aggregation",
    name: "Aggregation",
    component: AggregationView,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: base_url + "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/monitor/accident",
    name: "Accident",
    component: Accident,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: "/monitor/parking",
    name: "parking",
    component: Parking,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: "/monitor/watching",
    name: "watching",
    component: Watch,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: "/monitor/summaries",
    name: "summaries",
    component: Summally,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: "/monitor/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: "/monitor/macroAnalysis",
    name: "MacroAnalysis",
    component: MacroAnalysis,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  },
  {
    path: "/monitor/macroAnalysisInfoDataDownload",
    name: "MacroAnalysisInfoDataDownload",
    component: MacroAnalysisInfoDataDownload,
    beforeEnter: (to, from, next) => {
      if (isloginCheck()) {
        next();
      } else {
        next({ path: base_url + "/" });
      }
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

function isloginCheck() {
  let isLogin = true;
  if (UserInfo.state.userInfo.user == undefined) {
    isLogin = false;
  }
  return isLogin;
}
