<template>
  <div id="video-contenar">
    <video ref="video_player" class="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered" />
  </div>
</template>

<script>
  import videojs from "video.js";
  import "video.js/dist/video-js.css";

  export default {
    name: "VideoPlayer",
    props: {
      options: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        player: null
      };
    },
    beforeDestroy() {
      this.destroyVideoPlayer();
    },
    methods: {
      createVideoPlayer() {
        if (!this.player) {
          this.player = videojs(this.$refs.video_player, this.options);

          var self = this;

          this.player.on("play", function() {
            self.notifyStartVideo();
          });
          this.player.on("pause", function() {
            self.notifyPauseVideo();
          });
          this.player.on("timeupdate", function() {
            self.notifyCurrentTime(this.currentTime());
          });
        }
      },
      destroyVideoPlayer() {
        if (this.player) {
          this.player.dispose();
          this.player = null;
        }
      },
      pauseVideoPlayer() {
        if (this.player) {
          this.player.pause();
        }
      },
      updateVideoPlayer() {
        if (this.options.sources[0] == "") {
          return;
        }
        this.createVideoPlayer();
        this.player.pause();
        let currentTime = this.player.currentTime();
        this.player.src(this.options.sources[0]);
        this.player.currentTime(currentTime);
      },
      notifyStartVideo() {
        this.$emit("startVideo");
      },
      notifyPauseVideo() {
        this.$emit("pauseVideo");
      },
      notifyCurrentTime(time) {
        this.$emit("updateCurrentTime", time);
      }
    }
  };
</script>

<style>
  #video-contenar {
    position: relative;
    width: 36vw;
  }
</style>
