import { Amplify } from "aws-amplify";
// Amplifyデータを暗号化するパッケージ
import { EncryptStorage } from "encrypt-storage";
// ローカルストレージに対してデータを暗号化して保存する
const es = new EncryptStorage("smart-pole-aws", {
  stateManagementUse: true
});

// 環境変数に設定されている稼働環境の値に応じて、認証とAPIの設定を変える
// 稼働環境の変数がない場合は1stとみなす
if (process.env.VUE_APP_WORKING_ENV === void 0 || process.env.VUE_APP_WORKING_ENV === "first") {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "ap-northeast-1",

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "ap-northeast-1_G8hPMbU72",

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "597ake70ek4islijaruoo8hskp",

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: "USER_SRP_AUTH",

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: "ap-northeast-1:1af61771-d88e-4afe-b07d-13979d32a26a",

      // encription storage package instance
      storage: es
    },
    API: {
      endpoints: [
        {
          name: "getSignedUrl",
          endpoint: "https://bcy03veh7k.execute-api.ap-northeast-1.amazonaws.com"
        },
        {
          name: "spitsServer",
          endpoint: "https://woven-alb-for-read-instance-1114408800.ap-northeast-1.elb.amazonaws.com"
        },
        {
          name: "spitsServerDev",
          endpoint: "http://spits-238687476.ap-northeast-1.elb.amazonaws.com"
        },
        {
          name: "spitsServerLocal",
          endpoint: "http://localhost:9090"
        },
        {
          name: "spitsServerCloud",
          endpoint: "https://bx1ktfzkzl.execute-api.ap-northeast-1.amazonaws.com",
          custom_header: async () => {
            return {
              "x-api-key": "z9aI3OSx678MXebjPMl4e3IoCUaGFXpk7h1rVJzd"
            };
          }
        },
        {
          name: "spitsAdminApiUrl",
          endpoint: "https://ggfh3hstc2.execute-api.ap-northeast-1.amazonaws.com",
          custom_header: async () => {
            return {
              "x-api-key": "9F0h1w79011nfIz0lCxr56SjNIEGlxs8WICeZ5ve"
            };
          }
        }
      ]
    }
  });
  // 2nd
} else if (process.env.VUE_APP_WORKING_ENV === "second") {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "ap-northeast-1",

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "ap-northeast-1_6MK7n1wXF",

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "1kchad195c188ahnfgvr0h7mok",

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: "USER_SRP_AUTH",

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: "ap-northeast-1:b33a4a16-1d4e-4f28-b737-67230ce30fe4"
    },
    API: {
      endpoints: [
        {
          name: "getSignedUrl",
          endpoint: "https://az8d0hxad5.execute-api.ap-northeast-1.amazonaws.com"
        },
        {
          name: "spitsServer",
          endpoint: "https://sp1-woven-alb-for-read-instance-293613338.ap-northeast-1.elb.amazonaws.com"
        },
        {
          name: "spitsServerDev",
          endpoint: "http://spits-238687476.ap-northeast-1.elb.amazonaws.com"
        },
        {
          name: "spitsServerLocal",
          endpoint: "http://localhost:9090"
        },
        {
          name: "spitsServerCloud",
          endpoint: "https://msp7h42144.execute-api.ap-northeast-1.amazonaws.com",
          custom_header: async () => {
            return {
              "x-api-key": "z9aI3OSx678MXebjPMl4e3IoCUaGFXpk7h1rVJzd"
            };
          }
        },
        {
          name: "spitsAdminApiUrl",
          endpoint: "https://1qnxet3kxc.execute-api.ap-northeast-1.amazonaws.com",
          custom_header: async () => {
            return {
              "x-api-key": "9F0h1w79011nfIz0lCxr56SjNIEGlxs8WICeZ5ve"
            };
          }
        }
      ]
    }
  });
}
