<template>
  <div class="select_area">
    <v-card color="#33c" transition="scroll-y-transition" v-show="areaListEnabled" id="area_list">
      <v-card-title style="color: #fff;">エリア一覧</v-card-title>
      <v-container id="area_menu">
        <v-row justify="center">
          <v-col md="4">
            <v-text-field v-model="areaKeyword" label="対象エリア" dense />
          </v-col>
          <v-col md="2">
            <v-checkbox v-model="notificationEnabled" label="検知対象" dense />
          </v-col>
          <v-col md="2">
            <v-checkbox v-model="nearMissEnabled" label="ヒヤリハット" dense />
          </v-col>
          <v-col md="2">
            <v-checkbox v-model="accidentEnabled" label="事故" dense />
          </v-col>
          <v-spacer />
        </v-row>
        <v-row justify="center" dense>
          <v-spacer />
          <v-col md="4">
            <v-select label="ヒヤリハット件数検出方法" :items="detectionMethodList" item-text="name" item-value="value" v-model="detectedMethod" dense />
          </v-col>
          <v-spacer />
          <v-col md="2" v-if="detectedMethod === 1">
            <v-text-field v-model="summarySinceHour" label="直近ヒヤリハット検出時間" type="number" dense />
          </v-col>
          <v-col md="5" v-else>
            <v-container>
              <v-row dense>
                <v-col>
                  <v-text-field type="date" v-model="detectionStartDate" label="検出開始日" />
                </v-col>
                <v-col>
                  <v-text-field type="time" v-model="detectionStartTime" label="検出開始時刻" />
                </v-col>
                <v-col md="1">
                  <span style="font-size: 0.8em;">～</span>
                </v-col>
                <v-col>
                  <v-text-field type="date" v-model="detectionEndDate" label="検出終了日" />
                </v-col>
                <v-col>
                  <v-text-field type="time" v-model="detectionEndTime" label="検出終了時刻" />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col md="2">
            <v-btn small @click="resetAreaList">
              再集計する
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
      <v-data-table
        :headers="listHeader"
        :items="displayAreaList"
        item-key="areaId"
        :items-per-page="-1"
        :sort-by="['nearMissNumber']"
        :sort-desc="[true]"
        hide-default-footer
        dense
        light
        id="area_list_table"
      >
        <template v-slot:item.areaId="{ item }">
          {{ toHexNumber(item.areaId) }}
        </template>
        <template v-slot:item.point="{ item }">
          <v-icon color="red" v-if="item.point !== null" @click="setCenter(item.point)">
            mdi-map-marker
          </v-icon>
        </template>
        <template v-slot:item.notificationFlag="{ item }">
          <v-icon color="red" v-if="item.notificationFlag">
            mdi-lightbulb-on-outline
          </v-icon>
        </template>
        <template v-slot:item.detail="{ item }">
          <v-btn small @click="movePoleSelect(item)">
            見る
          </v-btn>
        </template>
        <template v-slot:item.watching="{ item }">
          <v-btn small @click="moveWatching(item)">
            見る
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <div id="area_map" />
    <v-main>
      <router-view />
    </v-main>
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import moment from "moment";
  import CommonDialog from "@/components/CommonDialog";
  import { STORE_ACTIONS } from "@/utils/config";
  import mathFunctions from "@/utils/math";
  import { commonFunction, communicationFunction, mapFunction, adminCommFunction } from "@/mixins/utils";
  import * as log from "loglevel";
  import router from "@/router";

  delete L.Icon.Default.prototype._getIconUrl;

  export default {
    name: "SelectArea",
    components: {
      CommonDialog
    },
    props: ["pointList"],
    mixins: [commonFunction, communicationFunction, mapFunction, adminCommFunction],
    data: function() {
      return {
        // ヒヤリハット件数検出方法
        detectionMethodList: [
          { name: "直近ヒヤリハット検出時間", value: 1 },
          { name: "検出開始、終了期間", value: 2 }
        ],
        // 期間選択方法(1: 直近ヒヤリハット検出時間、2: 検出開始、終了期間)
        detectedMethod: 1,
        // 取得開始、終了日時を、日付、時刻を別々に取得する
        detectionStartDate: "",
        detectionStartTime: "",
        detectionEndDate: "",
        detectionEndTime: "",
        // setting.json内の設定値一覧
        setting: undefined,
        // APIから取得したエリア一覧
        areaList: [],
        // 画面表示用一覧
        enhancedAreaList: [],
        displayAreaList: [],
        adminPoleList: [],
        markers: [],
        map: undefined,
        selectId: undefined,
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        // 過去件数を取得する上の対象時間
        summarySinceHour: 24,
        // 絞り込み項目
        areaKeyword: "",
        notificationEnabled: false,
        nearMissEnabled: false,
        accidentEnabled: false,
        areaListEnabled: false,
        listHeader: [
          { text: "エリアID", value: "areaId", align: "right" },
          { text: "エリア名", value: "name" },
          { text: "場所拡大", value: "point", align: "center" },
          { text: "ポール数", value: "poleNumber", align: "right" },
          { text: "検知状況", value: "notificationFlag", align: "center" },
          { text: "ヒヤリハット件数", value: "nearMissNumber", align: "right" },
          { text: "事故件数", value: "accidentNumber", align: "right" },
          { text: "詳細", value: "detail" },
          { text: "見守り画面", value: "watching" }
        ]
      };
    },
    created() {
      this.initialize();
    },
    mounted() {
      this.createAreaMap();
    },
    watch: {
      enhancedAreaList: {
        async handler(val) {
          this.displayAreaList = val;
        },
        deep: true
      },
      displayAreaList: {
        async handler() {
          this.addPointInfo();
          this.$emit("areaListCreated", this.areaList);
          this.areaListEnabled = true;
        },
        deep: true
      },
      pointList: {
        async handler() {
          this.addPointInfo();
        },
        deep: true
      },
      areaKeyword() {
        this.filteringList();
      },
      notificationEnabled() {
        this.filteringList();
      },
      nearMissEnabled() {
        this.filteringList();
      },
      accidentEnabled() {
        this.filteringList();
      }
    },
    methods: {
      ...mathFunctions,
      initialize() {
        this.setting = this.getSetting();
        this.setAreaList();
      },
      /**
       * エリア一覧を設定する
       */
      async setAreaList() {
        console.log("set");
        // エリア一覧情報を取得する
        let promise = this.updateAreaList();
        promise
          .then(() => {
            this.areaList = this.$store.state.areaList;
          })
          .then(() => {
            // フォーム上に指定されている取得開始、終了日時を設定する
            let fromTime = null;
            let toTime = null;
            // 直近ヒヤリハット検出時間
            if (this.detectedMethod === 1) {
              toTime = moment();
              fromTime = moment().subtract(this.summarySinceHour, "hours");
              // 検出開始、終了期間
            } else {
              toTime = moment(this.detectionEndDate + " " + this.detectionEndTime, "YYYY/MM/DD HH:mm");
              fromTime = moment(this.detectionStartDate + " " + this.detectionStartTime, "YYYY/MM/DD HH:mm");
            }
            // ヒヤリハット、事故件数取得と、ポール情報を同時に取得
            const promise = Promise.all([
              this.getNearMissSummary(null, null, fromTime.format("YYYY/MM/DD HH:mm:ss"), toTime.format("YYYY/MM/DD HH:mm:ss")),
              this.getAdminPoleList()
            ]);
            promise
              .then(() => {
                for (let area of this.areaList) {
                  // 一覧出力のために一部の値を初期設定する
                  area.latlng = area.latlng = [area.latitude, area.longitude];
                  area.poleNumber = 0;
                  area.notificationFlag = false;
                  area.nearMissNumber = 0;
                  area.accidentNumber = 0;
                  // ポール単位の検知状況を取得して設定する
                  for (let pole of this.$store.state.adminPoleList) {
                    if (area.areaId === pole.areaId) {
                      if (pole.notificationFlag) {
                        area.notificationFlag = true;
                      }
                    }
                  }
                  // エリアごとのヒヤリハット、事故件数を算出する
                  for (let pole of this.$store.state.nearMissSummaryList) {
                    if (pole.areaId === "0x" + ("00000000" + area.areaId.toString(16)).slice(-8)) {
                      area.notificationFlag;
                      area.poleNumber++;
                      area.nearMissNumber += pole.nearmisses;
                      area.accidentNumber += pole.accidents;
                    }
                  }
                }
              })
              .then(() => {
                this.enhancedAreaList = this.areaList;
              })
              .catch(error => {
                let title = this.str.dialog.title.getError;
                let message = this.str.dialog.message.getErrorAreaInfo;
                if (error == this.errcode.noContent) {
                  message = this.str.dialog.message.getErrorAreaNoContent;
                }
                this.showErrorDialig(title, message);
                this.$emit("areaListCreated", this.areaList);
              });
          })
          .then(() => {
            // 地図内のエリア地点にマーカーを設定する
            this.addAreaMarker(this.areaList);
          })
          .catch(error => {
            console.log(error);
            // エラーメッセージを設定する
            let title = this.str.dialog.title.getError;
            let message = this.str.dialog.message.getErrorAreaInfo;
            if (error == this.errcode.noContent) {
              message = this.str.dialog.message.getErrorAreaNoContent;
            }
            this.showErrorDialig(title, message);
            this.$emit("areaListCreated", this.areaList);
          });
      },
      /**
       * ダイアログ上にエラーメッセージを設定して表示
       * @param {String} title タイトル
       * @param {String} message メッセージ
       */
      showErrorDialig(title, message) {
        this.dialog.title = title;
        this.dialog.message = message;
        this.dialog.isDialog = true;
      },
      /**
       * 画面に地図を表示する
       */
      createAreaMap() {
        let zoom = this.setting.selectArea.zoom.default;
        let zoom_mim = this.setting.selectArea.zoom.min;
        let zoom_max = this.setting.selectArea.zoom.max;
        let center = [this.setting.selectArea.center.latitude, this.setting.selectArea.center.longitude];

        L.Icon.Default.mergeOptions(this.getMarkerIcon(false));

        this.map = L.map("area_map", {
          dragging: true,
          touchZoom: true,
          scrollWheelZoom: true,
          doubleClickZoom: true,
          boxZoom: true,
          tap: true,
          keyboard: true,
          zoomControl: true,
          minZoom: zoom_mim,
          maxZoom: zoom_max
        }).setView(center, zoom);

        L.control.layers(this.getTileLayer(this.map, zoom_max, undefined)).addTo(this.map);
        L.control.scale({ imperial: false }).addTo(this.map);
      },
      addAreaMarker(areaList) {
        areaList.forEach(area => {
          let latlng = [area.latitude, area.longitude];
          this.addMarker(latlng, area.name, area.areaId, L.icon(this.getMarkerIcon(false)));
        });
      },
      addMarker(latlng, name, areaId, icon) {
        let marker = L.marker(latlng, {
          title: name,
          icon: icon
        }).addTo(this.map);
        marker.on(
          "click",
          function(e) {
            this.selectMarker(areaId, e);
          }.bind(this)
        );

        this.markers.push({
          id: areaId,
          name: name,
          latlng: latlng,
          marker: marker
        });
      },
      selectMarker(areaId, e) {
        let latlng = e.latlng;
        let name = e.target.getElement().title;
        this.setSelectArea(areaId, name, latlng);
        this.$emit("pageSelected", name, areaId);
      },
      setSelectArea(areaId, name, latlng) {
        try {
          this.$store.dispatch(STORE_ACTIONS.setSelectArea, {
            areaId: areaId,
            name: name,
            latlng: latlng
          });
        } catch (error) {
          log.error("setSelectArea error " + error);
        }
      },
      setCenter(data) {
        // 具体的な場所を見やすくするために拡大
        let zoomLevel = this.map.getZoom();
        if (zoomLevel < 10) {
          zoomLevel = 10;
        }
        this.map.setView(data.latlng, zoomLevel);

        if (this.selectId != undefined) {
          let marker = this.getMarkerInfo(this.selectId, this.markers);
          this.map.removeLayer(marker.marker);
          this.addMarker(marker.latlng, marker.name, marker.id, L.icon(this.getMarkerIcon(false)));
        }

        let markerInfo = this.getMarkerInfo(data.id, this.markers);
        this.map.removeLayer(markerInfo.marker);
        this.addMarker(markerInfo.latlng, markerInfo.name, markerInfo.id, L.icon(this.getMarkerIcon(true)));
        this.selectId = data.id;
      },
      enterPoint(data) {
        this.setSelectArea(data.id, data.name, data.latlng);
        this.nextView();
      },
      /**
       * ポール選択画面へ遷移する
       * @param {Object} data エリア情報
       */
      movePoleSelect(data) {
        // 紐づいているポールが1つだけの場合、ダイレクトにリアルタイムモニターに遷移する
        if (data.poleNumber === 1) {
          this.setSelectPole(data.poleList[0].poleId, " ");
          router.push({ path: this.getRouterPath("/monitor/realtime") });
        } else {
          this.setSelectArea(data.areaId, data.name, data.latlng);
          router.push({ path: this.getRouterPath("/monitor/selectpole") });
        }
      },
      /**
       * 見守り画面へ遷移
       * @param {Object} data エリア情報
       */
      moveWatching(data) {
        this.setSelectArea(data.areaId, data.name, data.latlng);
        router.push({ path: this.getRouterPath("/monitor/watching") });
      },
      /**
       * 表示用エリア一覧にポイント情報を追加する
       */
      addPointInfo() {
        // 表示用エリア一覧とポイント一覧がともに取得完了したら実施する
        if (this.displayAreaList !== void 0 && this.pointList !== void 0) {
          // エリアIDが一致するポイント情報があったら追加する
          for (let area of this.displayAreaList) {
            const newPoint = this.pointList.find(point => point.id === area.areaId);
            area.point = newPoint !== void 0 ? newPoint : null;
          }
        }
      },
      /**
       * 一覧表にフィルタリングを行う
       */
      filteringList() {
        let work = [];
        const regexp = "^" + this.areaKeyword;
        this.enhancedAreaList.forEach(area => {
          if ((this.areaKeyword !== "" && area.name.match(regexp) !== null) || this.areaKeyword === "") {
            if ((this.notificationEnabled && area.notificationFlag) || !this.notificationEnabled) {
              if ((this.nearMissEnabled && area.nearMissNumber > 0) || !this.nearMissEnabled) {
                if ((this.accidentEnabled && area.accidentNumber > 0) || !this.accidentEnabled) {
                  work.push(area);
                }
              }
            }
          }
        });
        this.displayAreaList = work;
        this.$parent.isLoading = false;
      },
      /**
       * 再入力された検出時間をもとに再集計を行う
       */
      async resetAreaList() {
        this.$parent.isLoading = true;
        this.setAreaList();
      }
    }
  };
</script>

<style>
  #area_map {
    z-index: 0;
    /* 絶対値で高さを指定しないと地図が表示されない */
    height: 95vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 15px;
  }

  #area_list {
    display: inline-table;
    position: absolute;
    left: 10vw;
    top: 0vh;
    width: 70vw;
    height: auto;
    opacity: 0.8;
    z-index: 100;
  }

  #area_menu {
    max-width: 70vw;
    background-color: #ddd;
  }

  #area_list_table {
    height: 30vh;
    overflow-y: auto;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
</style>
