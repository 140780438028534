<template>
  <v-container>
    <v-data-table
      nav
      dense
      :icon="directionIcon"
      :headers="tableHeaders"
      :items="targetLedSensorDsiplayList"
      :items-per-page="itemsPerPage"
      :footer-props="{ itemsPerPageOptions: [itemsPerPage] }"
    >
      <template v-slot:item.id="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:item.dispLevel="{ item }">
        {{ item.isShow ? item.dispLevel : "" }}
      </template>
      <template v-slot:item.mark1Object="{ item }">
        <img v-if="item.mark1Object != '' && item.isShow" :src="item.mark1Object" style="width: 20px; height: 20px" />
      </template>
      <template v-slot:item.mark1directionStyle="{ item }">
        <img v-if="item.mark1direction == '-01' && item.isShow" :src="item.mark1directionStyle" style="width: 20px; height: 20px" />
        <img v-if="item.mark1direction != '-01' && item.mark1direction != '-10' && item.isShow" :src="directionIcon" :style="item.mark1directionStyle" />
      </template>
      <template v-slot:item.mark2Object="{ item }">
        <img v-if="item.mark2Object != '' && item.isShow" :src="item.mark2Object" style="width: 20px; height: 20px" />
      </template>
      <template v-slot:item.mark2directionStyle="{ item }">
        <img v-if="item.mark2direction == '-01' && item.isShow" :src="item.mark2directionStyle" style="width: 20px; height: 20px" />
        <img v-if="item.mark2direction != '-01' && item.mark2direction != '-10' && item.isShow" :src="directionIcon" :style="item.mark2directionStyle" />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import { commonFunction, mapFunction } from "@/mixins/utils";

  export default {
    name: "LedInfoTable",
    components: {},
    mixins: [commonFunction, mapFunction],
    props: ["selectSensor", "ledSensorList", "mode"],
    data: () => ({
      ledList: [],
      targetLedSensorList: [],
      targetLedSensorDsiplayList: [],
      ledDisplayLevel: undefined,
      itemsPerPage: 3,
      tableHeaders: [
        { text: "センサーID", value: "id", sortable: true },
        { text: "情報", value: "dispLevel", sortable: false },
        { text: "マーク１", value: "mark1Object", sortable: false },
        { text: "方路１", value: "mark1directionStyle", sortable: false },
        { text: "マーク２", value: "mark2Object", sortable: false },
        { text: "方路２", value: "mark2directionStyle", sortable: false }
      ]
    }),
    created() {
      this.initialize();
      this.setting = this.getSetting();
      this.ledDisplayLevel = this.setting.ledDisplayLevel;
    },
    methods: {
      initialize() {
        for (const ledInfo of this.ledSensorList) {
          let info = {
            id: ledInfo.name,
            updatetime: new Date().getTime(),
            isScondMinuteUpdate: true,
            isShow: false,
            resTime: "",
            dispKind: "",
            mark1: "",
            mark1Object: "",
            mark1direction: "",
            mark1directionStyle: "",
            mark2: "",
            mark2Object: "",
            mark2direction: "",
            mark2directionStyle: "",
            dispLevel: ""
          };
          this.targetLedSensorList.push(info);
        }
      },
      updateList() {
        // 最新データを設定
        let sensorLedList = this.$store.state.ledList;
        sensorLedList.forEach(sensorLed => {
          this.targetLedSensorList.forEach(led => {
            if (led.id == sensorLed.sensorId.toString(16)) {
              led.updatetime = new Date().getTime();
              led.resTime = new Date(sensorLed.ledList[0].resTime).getTime();
              led.dispKind = sensorLed.ledList[0].dispKind;
              led.mark1 = sensorLed.ledList[0].dispInfo1;
              led.mark1Object = this.getLedObjectMarker(sensorLed.ledList[0].dispKind, sensorLed.ledList[0].dispInfo1);
              led.mark1direction = sensorLed.ledList[0].direction1;
              led.mark1directionStyle = this.getLedDirectionStyle(sensorLed.ledList[0].direction1);
              led.mark2 = sensorLed.ledList[0].dispInfo2;
              led.mark2Object = this.getLedObjectMarker(sensorLed.ledList[0].dispKind, sensorLed.ledList[0].dispInfo2);
              led.mark2direction = sensorLed.ledList[0].direction2;
              led.mark2directionStyle = this.getLedDirectionStyle(sensorLed.ledList[0].direction2);
              led.dispLevel = this.getdisplayLevel(sensorLed.ledList[0].dispKind, sensorLed.ledList[0].dispLevel);
            }
          });
        });
        // 2分間更新が行われていないかチェック
        this.targetLedSensorList.forEach(led => {
          if (led.resTime == "") {
            led.isScondMinuteUpdate = false;
          } else {
            // リアルタイム画面の場合のみフロント側で測定
            if (this.mode == "realTime") {
              led.isScondMinuteUpdate = this.updatetimeCheck(led.resTime, led.updatetime);
            }
          }
        });
        // 選択した選択したセンサーを表示対象に変更
        this.targetLedSensorDsiplayList = [];
        this.selectSensor.forEach(sensor => {
          this.targetLedSensorList.forEach(led => {
            if (led.id == sensor.name) {
              this.targetLedSensorDsiplayList.push(led);
            }
          });
        });
        this.targetLedSensorDsiplayList.forEach(led => {
          led.isShow = this.chekDisplay(led);
        });
      },
      updatetimeCheck(resTime, updatetime) {
        let d1 = new Date(resTime);
        let diffTime = d1.getTime() - updatetime;
        let diffSecond = Math.floor(diffTime / 1000);
        let isupdate = false;
        if (diffSecond <= 120) {
          isupdate = true;
        }
        return isupdate;
      },
      getLedDirectionStyle(direction) {
        // -01：不明 -10：なし
        if (direction == "-10") {
          return "";
        } else if (direction == "-01") {
          return require("@/assets/img/object/question.svg");
        }
        return {
          width: "20px",
          height: "20px",
          transform: "rotate(" + direction + "deg)"
        };
      },
      getdisplayLevel(dispKind, dispLevel) {
        let level = "";
        // ブランクなら取得しない 000
        if (dispKind == "000") {
          return level;
        }
        // 安全運転支援
        const safeDrivingSupportDispKind = "001";
        // 災害
        const disasterDispKind = "007";
        let targetList = null;
        if (dispKind == safeDrivingSupportDispKind) {
          targetList = this.ledDisplayLevel.safeDrivingSupport;
        } else if (dispKind == disasterDispKind) {
          targetList = this.ledDisplayLevel.disaster;
        }
        if (targetList != null) {
          for (const item of targetList) {
            if (dispLevel == item.kind) {
              level = item.displayName;
              break;
            }
          }
        }
        return level;
      },
      chekDisplay(led) {
        return led.dispKind != "000" && led.isScondMinuteUpdate && led.resTime != "";
      },
      update() {
        this.updateList();
      }
    },
    watch: {
      selectSensor() {
        this.updateList();
      },
      ledSensorList() {
        this.initialize();
      }
    }
  };
</script>

<style></style>
