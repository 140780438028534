<template>
  <div class="virtualmap">
    <!-- /* 絶対値で高さを指定しないと地図が表示されない */ -->
    <div id="virtual_map" v-bind:style="{ height: isRealTime ? '60vh' : '82vh' }" />
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import "leaflet-semicircle";
  import "leaflet-rotatedmarker";
  import { commonFunction, mapFunction } from "../mixins/utils";

  delete L.Icon.Default.prototype._getIconUrl;

  export default {
    name: "VirtualMap",
    mixins: [commonFunction, mapFunction],
    props: ["accident", "selectSensor", "isRealTime"],
    data: function() {
      return {
        setting: undefined,
        poleMarkers: [],
        objectMarkers: [],
        sensorMarkers: [],
        ledMarkers: [],
        map: undefined,
        sensorList: undefined,
        accidentPoint: undefined
      };
    },
    created() {
      this.initialize();
    },
    mounted() {
      this.createMap();
    },
    methods: {
      initialize() {
        this.setting = this.getSetting();
        this.sensorList = this.$store.state.selectPole.sensorList;
        this.accidentPoint = this.$store.state.selectAccident.latlng;
      },
      createMap() {
        let latlng = this.$store.state.selectPole.latlng;
        let zoom = this.setting.virtualMap.zoom.default;
        let zoom_min = this.setting.virtualMap.zoom.min;
        let zoom_max = this.setting.virtualMap.zoom.max;
        let zoom_max_native = this.setting.virtualMap.zoom.maxNative;

        L.Icon.Default.mergeOptions(this.getMarkerIcon(false));

        this.map = L.map("virtual_map", {
          dragging: true,
          touchZoom: true,
          scrollWheelZoom: true,
          doubleClickZoom: true,
          boxZoom: true,
          tap: true,
          keyboard: true,
          zoomControl: true,
          minZoom: zoom_min,
          maxZoom: zoom_max
        }).setView(latlng, zoom);

        L.control.layers(this.getTileLayer(this.map, zoom_max, zoom_max_native)).addTo(this.map);

        this.addPoleMarker(latlng);

        if (this.accident) {
          this.addAccidentObjectMarker(this.accidentPoint);
        }

        this.addSensorArea();
      },
      addPoleMarker(latlng) {
        this.poleMarkers = null;
        this.poleMarkers = L.marker(latlng).addTo(this.map);
      },
      addLedMarker(latlng) {
        L.Icon.Default.mergeOptions(this.getLedMarkerIcon());
        this.ledMarkers.push(L.marker(latlng).addTo(this.map));
      },
      removeLedMarker() {
        for (const item of this.ledMarkers) {
          this.map.removeLayer(item);
        }
      },
      updatePoleMarker() {
        this.removePoleMarker();
        this.addPoleMarker(this.$store.state.selectPole.latlng);
      },
      removePoleMarker() {
        this.map.removeLayer(this.poleMarkers);
      },
      addAccidentObjectMarker(latlng) {
        let Icon = L.icon({
          iconUrl: this.accidentIcon,
          iconSize: [20, 20] // アイコンサイズ
        });
        let marker = L.marker(latlng, {
          icon: Icon,
          rotationOrigin: "center center"
        });
        marker.addTo(this.map);
      },
      addSensorArea() {
        this.removeLedMarker();
        let list = this.sensorList;
        list.forEach(sensor => {
          let type = this.checkSensorType(sensor.sensorId);
          let ischeck = this.checkSensor(sensor.sensorId);
          if (ischeck && type != "表示板") {
            let sensorSetting = this.getSensorSetting(sensor.sensorId);
            this.addSensorAreaMarker(sensor, sensorSetting.color, sensorSetting.name);
          } else if (ischeck && type == "表示板") {
            this.addLedMarker([sensor.latitude, sensor.longitude]);
          }
        });
      },
      updateSensorArea() {
        this.sensorList = this.$store.state.selectPole.sensorList;
        this.removeSensorAreaMarker();
        this.addSensorArea();
      },
      checkSensorType(sensorId) {
        let type = "";
        this.selectSensor.forEach(sensor => {
          let sensorKind = sensorId;
          if (sensorKind == sensor.value) {
            type = sensor.kind;
            return;
          }
        });
        return type;
      },
      checkSensor(sensorId) {
        let result = false;
        this.selectSensor.forEach(sensor => {
          let sensorKind = sensorId;
          if (sensorKind == sensor.value) {
            result = true;
            return;
          }
        });
        return result;
      },
      addSensorAreaMarker(sensor, color, name) {
        let latlng = [sensor.latitude, sensor.longitude];
        let directionMin = sensor.detectDirectionMin;
        let directionMax = sensor.detectDirectionMax;
        let radius = sensor.detectDistanceMax;

        if (directionMin == 65535 || directionMax == 65535 || radius == 65535) {
          return;
        }

        if (sensor.detectDirectionMin > sensor.detectDirectionMax) {
          directionMax += 360;
        }
        let semicircle = L.semiCircle(latlng, {
          radius: radius,
          startAngle: directionMin,
          stopAngle: directionMax,
          color: color,
          opacity: 0.0,
          fillOpacity: 0.3,
          weight: 1
        });
        semicircle.bindTooltip(name, {
          className: "sensorArea",
          direction: "center",
          offset: L.point(20, -50),
          opacity: 0.6
        });
        semicircle.addTo(this.map);

        this.sensorMarkers.push({
          sensorId: sensor.sensorId,
          marker: semicircle
        });
      },
      removeSensorAreaMarker() {
        this.sensorMarkers.forEach(marker => {
          this.map.removeLayer(marker.marker);
        });
        this.sensorMarkers = null;
        this.sensorMarkers = [];
      },
      updateObject() {
        let sensorPosList = this.$store.state.posList;
        this.removeObjectMarker();
        sensorPosList.forEach(sensorPos => {
          this.selectSensor.forEach(sensor => {
            let sensorKind = sensorPos.sensorId;
            if (sensorKind == sensor.value) {
              let posList = sensorPos.posList;
              this.updateObjectMarker(posList, sensorPos.sensorId);
              return;
            }
          });
        });
      },
      updateObjectMarker(posList, sensorId) {
        posList.forEach(pos => {
          this.addObjectMarker(sensorId, pos.vehicleId, [pos.latitude, pos.longitude], pos.heading, this.getMapMarker(pos.vehicleSizeClassification, false));
        });
      },
      addObjectMarker(sensorId, vehicleId, latlng, heading, icon) {
        let Icon = L.icon({
          iconUrl: icon,
          iconSize: [20, 20] // アイコンサイズ
        });
        let direction = 0;
        let marker = L.marker(latlng, {
          icon: Icon,
          rotationAngle: direction,
          rotationOrigin: "center center"
        });
        marker.bindTooltip(vehicleId.toString(), {
          className: "markerObject",
          direction: "center",
          offset: L.point(10, -25),
          permanent: true,
          opacity: 0.6
        });
        marker.addTo(this.map);

        this.objectMarkers.push({
          sensorId: sensorId,
          vehicleId: vehicleId,
          marker: marker
        });
      },
      checkObjectMarker(sensorId, vehicleId) {
        let result = undefined;
        this.objectMarkers.forEach(marker => {
          if (marker.sensorId == sensorId && marker.vehicleId == vehicleId) {
            result = marker;
            return;
          }
        });
        return result;
      },
      removeObjectMarker() {
        this.objectMarkers.forEach(marker => {
          this.map.removeLayer(marker.marker);
        });
        this.objectMarkers = null;
        this.objectMarkers = [];
      },
      updatePos() {
        this.updateObject();
      },
      updatePole() {
        this.updatePoleMarker();
      },
      updateSensor() {
        this.updateSensorArea();
      },
      setCenter(latlng) {
        this.map.setView(latlng, this.map.getZoom());
      }
    },
    watch: {
      selectSensor() {
        this.updateObject();
        this.updateSensorArea();
      }
    }
  };
</script>

<style>
  #virtual_map {
    z-index: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 15px;
  }

  .markerObject {
    background: darkcyan;
    color: white;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    border: 2px solid gray;
    border-radius: 40px;
    width: 40px;
    height: 30px;
  }
</style>
