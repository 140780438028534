<template>
  <div class="selectpole">
    <div id="pole_map" />
    <v-card transition="scroll-y-transition" v-show="!$parent.isLoading" id="pole_list">
      <v-toolbar dark color="#33c">
        <v-toolbar-title>ポール一覧</v-toolbar-title>
      </v-toolbar>
      <v-data-table :headers="poleListHeader" :items="displayPoleList" item-key="id" :items-per-page="-1" hide-default-footer dense light id="pole_list_table">
        <template v-slot:item.id="{ item }">
          {{ toHexNumber(item.id) }}
        </template>
        <template v-slot:item.status="{ item }">
          <div @click="dispSensorDetail(item.id)">
            <v-btn icon>
              <v-icon color="#3c3" v-if="item.sensorStatus === 0">
                mdi-circle-outline
              </v-icon>
              <v-icon color="#c00" v-else>
                mdi-close-thick
              </v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.nearMissNumberTotal="{ item }">
          {{ item.nearMissNumberTotal === void 0 ? "-" : item.nearMissNumberTotal }}
        </template>
        <template v-slot:item.goneThroughNumberTotal="{ item }">
          {{ item.goneThroughNumberTotal === void 0 ? "-" : item.goneThroughNumberTotal }}
        </template>
        <template v-slot:item.nowInfo="{ item }">
          <v-btn small @click="moveRealTimeMonitor(item)">
            見る
          </v-btn>
        </template>
        <template v-slot:item.pastInfo="{ item }">
          <v-btn small @click="movePastTimeMonitor(item)">
            見る
          </v-btn>
        </template>
        <template v-slot:item.nearmiss="{ item }">
          <v-btn small @click="moveAccident(item)">
            見る
          </v-btn>
        </template>
        <template v-slot:item.calc="{ item }">
          <v-btn small @click="moveAggregation(item)">
            見る
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-main>
      <router-view />
    </v-main>
    <CommonDialog :dialog="dialog" />
    <v-card transition="scroll-y-transition" v-show="!$parent.isLoading && sensorListEnabled" id="sensor_list">
      <v-toolbar dark color="#33c">
        <v-toolbar-title>センサー一覧</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="sensorListEnabled = false">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table :headers="sensorListHeader" :items="displaySensorList" item-key="id" :items-per-page="-1" hide-default-footer light dense id="sensor_list_table">
        <template v-slot:item.status="{ item }">
          <v-icon color="#3c3" v-if="item.status">
            mdi-circle-outline
          </v-icon>
          <v-icon color="#c00" v-else>
            mdi-close-thick
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import moment from "moment";
  import CommonDialog from "@/components/CommonDialog";
  import mathFunctions from "@/utils/math";
  import { commonFunction, communicationFunction, mapFunction, adminCommFunction } from "@/mixins/utils";
  import router from "@/router";
  import { STORE_ACTIONS } from "@/utils/config";
  import settingJson from "@/assets/setting/setting";

  delete L.Icon.Default.prototype._getIconUrl;

  export default {
    name: "SelectPole",
    components: {
      CommonDialog
    },
    props: ["pointList"],
    mixins: [commonFunction, communicationFunction, mapFunction, adminCommFunction],
    data: function() {
      return {
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        setting: undefined,
        markers: [],
        map: undefined,
        areaId: undefined,
        poleList: [],
        poleDetailList: [],
        selectId: undefined,
        isPoiList: false,
        displayPoleList: [],
        displaySensorList: [],
        sensorListEnabled: false,
        poleListHeader: [
          { text: "ID", value: "id", width: "10em", sortable: false },
          { text: "ポール名", value: "name", width: "22em", sortable: false },
          { text: "ｾﾝｻｰ状態", value: "status", align: "center", width: "8em", sortable: false },
          { text: "ﾋﾔﾘﾊｯﾄ(24h)", value: "nearMissNumber", align: "right", width: "11em" },
          { text: "ﾋﾔﾘﾊｯﾄ(全件)", value: "nearMissNumberTotal", align: "right", width: "13em" },
          { text: "通過(24h)", value: "goneThroughNumber", align: "right", width: "10em" },
          { text: "通過(全件)", value: "goneThroughNumberTotal", align: "right", width: "12em" },
          { text: "ﾘｱﾙﾀｲﾑ", value: "nowInfo", width: "8em", sortable: false },
          { text: "過去情報", value: "pastInfo", width: "8em", sortable: false },
          { text: "事故情報", value: "nearmiss", width: "8em", sortable: false },
          { text: "集計", value: "calc", width: "6em", sortable: false }
        ],
        sensorListHeader: [
          { text: "ID", value: "id", align: "right", width: "80px" },
          { text: "種類", value: "kind", align: "right", width: "80px" },
          { text: "センサー名", value: "name" },
          { text: "最終データ取得日時", value: "updated", width: "180px" },
          { text: "ステータス", value: "status" }
        ]
      };
    },
    created() {
      this.initialize();
    },
    mounted() {
      this.createPoleMap();
    },
    methods: {
      ...mathFunctions,
      /**
       * 初期設定
       */
      initialize() {
        // setting.jsonの内容を取得
        this.setting = this.getSetting();
        // VuexストアからエリアIDを取得
        this.areaId = this.$store.state.selectArea.areaId;
        // ポール一覧情報を設定する
        this.setPoleList(this.areaId);
      },
      /**
       * ポール一覧情報を設定する
       * @param {Number} areaId エリアID
       */
      async setPoleList(areaId) {
        // Vuexストア上のポール一覧情報にあるエリアIDがアクセス対象のものの場合、Vuexストア上のステートから取得する
        // それ以外の場合はAPIから取得する
        if (this.$store.state.displayPoleList.areaId !== areaId) {
          // Vuexステートの内容をリセットする
          this.$store.dispatch(STORE_ACTIONS.setDisplayPoleList, {
            areaId: 0,
            poleList: [],
            dispPoleList: []
          });
          let promise = this.updatePoleListAsAreaId(areaId);
          promise
            .then(async () => {
              await this.createPoleList();
            })
            .then(() => {
              // 一覧表示用のポール一覧を生成する
              this.poleList.forEach(pole => {
                let addressName = pole.addressElements.province !== void 0 ? pole.addressElements.province : " ";
                addressName += pole.addressElements.city !== void 0 ? pole.addressElements.city : " ";
                addressName += pole.addressElements.neighbourhood !== void 0 ? pole.addressElements.neighbourhood : " ";
                addressName += pole.addressElements.road !== void 0 ? pole.addressElements.road : " ";
                addressName += pole.addressElements.man_made !== void 0 ? pole.addressElements.man_made : " ";

                this.displayPoleList.push({
                  id: pole.poleId,
                  name: addressName,
                  latlng: pole.latlng,
                  nearMissNumber: pole.nearMissNumber,
                  accidentNumber: pole.accidentNumber,
                  goneThroughNumber: pole.goneThroughNumber
                });
              });
            })
            .then(() => {
              // 取得したポール一覧と表示用のポール一覧情報をVuexストア上に更新を行う
              this.$store.dispatch(STORE_ACTIONS.setDisplayPoleList, {
                areaId: areaId,
                poleList: this.poleList,
                dispPoleList: this.displayPoleList
              });
            })
            .catch(error => {
              let title = this.str.dialog.title.getError;
              let message = this.str.dialog.message.getErrorPoleInfo;
              if (error == this.errcode.noContent) {
                message = this.str.dialog.message.getErrorPoleNoContent;
              }
              this.showErrorDialig(title, message);
              this.$emit("poleListCreated", this.poleList);
            });
        } else {
          this.poleList = this.$store.state.displayPoleList.poleList;
          this.displayPoleList = this.$store.state.displayPoleList.dispPoleList;
          // 地図データが読み込まれていたらマーカー情報を追加する
          if (this.map !== void 0) {
            this.addPoleMarker();
          } else {
            this.isPoleList = true;
          }
          this.$emit("poleListCreated", this.poleList);
        }
      },
      showErrorDialig(title, message) {
        this.dialog.title = title;
        this.dialog.message = message;
        this.dialog.isDialog = true;
      },
      /**
       * ポール一覧を生成する
       */
      async createPoleList() {
        // Vuexステートに保存された一覧を取得する
        let list = this.$store.state.poleList;
        for (let i = 0; i < list.length; i++) {
          try {
            // OpenStreetMapから緯度・経度を指定し住所や地名を取得
            let response = await this.addressByCoordinates(list[i].latitude, list[i].longitude);
            // 取得に成功したらポール情報をまとめる
            if (response.ok) {
              // ポール名を一つの文字列にする
              let address = "";
              address += response.body.address.province !== void 0 ? response.body.address.province : "";
              if (response.body.address.city !== void 0) {
                address += response.body.address.city;
              }
              if (response.body.address.county !== void 0) {
                address += response.body.address.county;
              }
              if (response.body.address.hamlet !== void 0) {
                address += response.body.address.hamlet;
              }
              if (response.body.address.suburb !== void 0) {
                address += response.body.address.suburb;
              }
              if (response.body.address.neighbourhood !== void 0) {
                address += response.body.address.neighbourhood;
              }
              if (response.body.address.road !== void 0) {
                address += response.body.address.road;
              }
              address += response.body.address.man_made !== void 0 ? response.body.address.man_made : "";

              let currentDate = moment();
              let fromTime = moment().subtract(24, "hours");

              // 事故件数を取得する
              const getAccNum = this.getAccidentNumber(
                list[i].poleId,
                [fromTime.format("YYYY/MM/DD"), currentDate.format("YYYY/MM/DD")],
                fromTime.format("HH:mm:ss"),
                currentDate.format("HH:mm:ss"),
                true
              );

              // ヒヤリハット件数を取得する
              const getNeaNum = this.getNearMissNumber(
                list[i].poleId,
                [fromTime.format("YYYY/MM/DD"), currentDate.format("YYYY/MM/DD")],
                fromTime.format("HH:mm:ss"),
                currentDate.format("HH:mm:ss"),
                30.0,
                true,
                true
              );

              const getGoneThroughNumber = this.addGoneThroughNumber(list[i].poleId);

              // 事故とヒヤリハットの件数取得を完了するまで、代入処理を行わない
              await Promise.all([getAccNum, getNeaNum, getGoneThroughNumber]).then(vals => {
                // センサー全体のステータスをチェックする
                let sensorStatus = 0;
                for (let sensor of list[i].sensorList) {
                  if (sensor.sensorStatus !== 0 || sensor.errorCode !== 0) {
                    sensorStatus = 1;
                  }
                }
                this.poleList.push({
                  poleId: list[i].poleId,
                  areaId: this.areaId,
                  latlng: [list[i].latitude, list[i].longitude],
                  latitude: list[i].latitude,
                  longitude: list[i].longitude,
                  address: address,
                  addressElements: response.body.address,
                  accidentNumber: vals[0],
                  nearMissNumber: vals[1],
                  goneThroughNumber: vals[2],
                  sensorList: list[i].sensorList,
                  sensorStatus: sensorStatus
                });
              });
            }
          } catch {
            // 取得に失敗したら、ポール名は不明とする
            this.poleList.push({
              poleId: list[i].poleId,
              latlng: [list[i].latitude, list[i].longitude],
              address: "unknown",
              sensorList: []
            });
          }
        }
        if (this.map !== void 0) {
          this.addPoleMarker();
        } else {
          this.isPoleList = true;
        }
        this.$emit("poleListCreated", this.poleList);
      },
      createPoleMap() {
        let latlng = this.$store.state.selectArea.latlng;
        let zoom = this.setting.selectPole.zoom.default;
        let zoom_min = this.setting.selectPole.zoom.min;
        let zoom_max = this.setting.selectPole.zoom.max;
        let zoom_max_native = this.setting.selectPole.zoom.maxNative;

        L.Icon.Default.mergeOptions(this.getMarkerIcon(false));

        this.map = L.map("pole_map", {
          dragging: true,
          touchZoom: true,
          scrollWheelZoom: true,
          doubleClickZoom: true,
          boxZoom: true,
          tap: true,
          keyboard: true,
          zoomControl: true,
          minZoom: zoom_min,
          maxZoom: zoom_max
        }).setView(latlng, zoom);

        L.control.layers(this.getTileLayer(this.map, zoom_max, zoom_max_native)).addTo(this.map);
        L.control
          .scale({
            maxWidth: 500,
            metric: true,
            imperial: false
          })
          .addTo(this.map);

        if (this.isPoleList) {
          this.addPoleMarker();
        }
      },
      addPoleMarker() {
        this.poleList.forEach(pole => {
          this.addMarker(pole.latlng, pole.address, pole.poleId, L.icon(this.getMarkerIcon(false)));
        });
        this.isPoleList = false;
      },
      addMarker(latlng, name, poleId, icon) {
        let marker = L.marker(latlng, {
          title: name,
          icon: icon
        }).addTo(this.map);
        marker.on(
          "click",
          function(e) {
            this.mouseClickEvent(poleId, e);
          }.bind(this)
        );

        this.markers.push({
          id: poleId,
          name: name,
          latlng: latlng,
          marker: marker
        });
      },
      mouseClickEvent(poleId, e) {
        let latlng = e.target.getLatLng();
        let title = e.target.getElement().title;
        let data = {
          id: poleId,
          latlng: latlng,
          name: title
        };
        this.setCenter(data);
        this.$emit("poleSelected", title, poleId);
      },
      setCenter(data) {
        this.map.setView(data.latlng, this.map.getZoom());

        if (this.selectId !== void 0) {
          let marker = this.getMarkerInfo(this.selectId, this.markers);
          this.map.removeLayer(marker.marker);
          this.addMarker(marker.latlng, marker.name, marker.id, L.icon(this.getMarkerIcon(false)));
        }

        let markerInfo = this.getMarkerInfo(data.id, this.markers);
        this.map.removeLayer(markerInfo.marker);
        this.addMarker(markerInfo.latlng, markerInfo.name, markerInfo.id, L.icon(this.getMarkerIcon(true)));
        this.selectId = data.id;
      },
      enterPoint(data) {
        this.setCenter(data);
        this.$emit("poleSelected", data.name, data.id);
      },
      /**
       * ポール一覧からリアルタイムモニターに遷移する
       * @param {Object} data ポール情報
       */
      moveRealTimeMonitor(data) {
        // 対象のポールをバーチャル映像用の地図の中心に表示するようにする
        this.enterPoint(data);
        // リアルタイムモニターに遷移する
        router.push({ path: this.getRouterPath("/monitor/realtime") });
      },
      movePastTimeMonitor(data) {
        this.enterPoint(data);
        router.push({ path: this.getRouterPath("/monitor/historylist"), query: { direct: false } });
      },
      moveAccident(data) {
        this.enterPoint(data);
        router.push({ path: this.getRouterPath("/monitor/accident") });
      },
      moveAggregation(data) {
        this.enterPoint(data);
        router.push({ path: this.getRouterPath("/monitor/aggregation") });
      },
      async dispSensorDetail(id) {
        this.displaySensorList = await this.setSensorList(id);
        this.sensorListEnabled = this.displaySensorList !== void 0 && this.displaySensorList.length > 0;
      },
      async addGoneThroughNumber(id) {
        let sensorIdList = [];
        let goneThroughNumber = 0;
        // 物標情報取得のためのセンサーID一覧を設定する
        const target = this.$store.state.poleList.find(pole => pole.poleId === id);
        if (target === void 0) {
          return;
        }
        for (let sensor of target.sensorList) {
          sensorIdList.push(sensor.sensorId);
        }
        let positionList = await this.getLatestPositionList(id, sensorIdList, 86400);
        if (positionList !== void 0 && positionList.length > 0) {
          for (let pos of positionList) {
            goneThroughNumber += pos.posList.length;
          }
        }
        return goneThroughNumber;
      },
      /**
       * 表示用センサー一覧情報を設定する
       * @param {Number} id 対象となるポールID
       */
      async setSensorList(id) {
        this.displaySensorList = [];
        let sensorHistoryList = [];
        // センサー名に追加する連番リストの初期設定
        let sensorCounterList = [];
        // センサー種別番号の最大値を取得
        const sensorList = settingJson.common.sensorList;
        let maxKind = 0;
        for (let sensor of sensorList) {
          if (sensor.kind > maxKind) {
            maxKind = sensor.kind;
          }
        }
        // 種別番号の最大値分+1のカウンター配列を生成(maxKind + 1は、不明のセンサー用)
        for (let i = 0; i <= maxKind + 1; i++) {
          sensorCounterList.push(1);
        }
        // センサー一覧を持つ対象のポール情報を抽出する
        const target = this.$store.state.poleList.find(pole => pole.poleId === id);
        if (target !== void 0) {
          const getSensorHistory = this.getPoleSensorHistory(id);
          return getSensorHistory
            .then(async val => {
              sensorHistoryList = val;
            })
            .then(() => {
              this.$parent.isLoading = false;
              // 連番用
              let counter = 1;
              // 一覧表示データを保存するための作業配列
              let work = [];
              // センサー情報を1件ずつ抽出する
              for (let sensor of target.sensorList) {
                let lastModified = moment();
                // 対象のセンサーの状態変化情報を取得する
                const targetSensorHistory = sensorHistoryList.find(history => history.sensorIdHex === sensor.sensorId);
                if (targetSensorHistory !== void 0) {
                  // 最終データ取得日時を取得する
                  lastModified = moment(targetSensorHistory.time);
                }
                // センサー種別を取得する
                let setting = this.getSensorSetting(sensor.sensorId);
                // 存在しない場合は不明に含める
                if (setting === void 0) {
                  setting = {
                    kind: maxKind + 1,
                    name: "不明"
                  };
                }
                let sensorName = "";
                // 名称の末尾に連番を追加する
                sensorName = setting.name + "-" + sensorCounterList[setting.kind];
                sensorCounterList[setting.kind]++;
                // 作業用配列にセンサー情報を追加する
                work.push({
                  id: counter,
                  kind: setting.kind,
                  name: sensorName,
                  number: 1,
                  updated: lastModified.format("YYYY/MM/DD HH:mm:ss"),
                  status: sensor.errorCode === 0 && sensor.sensorStatus === 0
                });
                counter++;
              }
              // 連番及びセンサーの種類番号でソートした後、連番を再採番する
              work.sort((a, b) => {
                return a.id - b.id && a.kind - b.kind;
              });
              for (let i = 0; i < work.length; i++) {
                work[i].id = i + 1;
              }
              return work;
            })
            .catch(error => {
              this.showErrorDialig("センサー履歴取得失敗", error);
            });
        } else {
          this.showErrorDialig("センサー情報取得失敗", "ポール情報に紐づくセンサー情報がありません");
          return;
        }
      }
    }
  };
</script>

<style>
  #pole_map {
    z-index: 0;
    /* 絶対値で高さを指定しないと地図が表示されない */
    height: 95vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 15px;
  }

  #pole_list {
    display: inline-table;
    position: absolute;
    left: 10vw;
    top: 1vh;
    height: auto;
    z-index: 100;
    opacity: 0.8;
    max-width: 80vw;
    overflow-x: auto;
  }

  #pole_list_table {
    max-width: 80vw;
    max-height: 30vh;
    overflow: auto;
  }

  #sensor_list_table {
    background-color: #eee;
    overflow-x: auto;
  }

  #sensor_list {
    display: inline-table;
    position: absolute;
    left: 5vw;
    top: calc(100vh - 500px);
    width: 50vw;
    max-width: 800px;
    overflow-y: auto;
    z-index: 102;
    opacity: 0.8;
    overflow-y: auto;
  }

  #sensor_list_table {
    height: 20vh;
    overflow-y: auto;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
</style>
