import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import { API } from "aws-amplify";
import { STORE_ACTIONS } from "@/utils/config";
import settingJson from "@/assets/setting/setting";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import * as log from "loglevel";
import moment from "moment";

/**
 * 共通処理
 */
export const commonFunction = {
  data() {
    return {
      // 文字列定義オブジェクト
      str: {
        // ダイアログのタイトル及びメッセージ一覧
        dialog: {
          title: {
            authError: "認証エラー",
            getError: "取得エラー",
            streamingError: "ストリーミングエラー"
          },
          message: {
            authErrorUser: "ユーザ名が入力されていません",
            authErrorPassword: "パスワードが入力されていません",
            authErrorUserPassword: "ユーザ名・パスワードが間違っています",
            authErrorOther: "ログインできませんでした",
            authErrorSigned: "認証情報が取得できませんでした\r\n動画再生はできません",
            authErrorSession: "セッションが切断されました",
            getErrorAreaNoContent: "エリア情報が存在しませんでした",
            getErrorAreaInfo: "エリア情報が取得できませんでした",
            getErrorPoleNoContent: "ポール情報が存在しませんでした",
            getErrorPoleInfo: "ポール情報が取得できませんでした",
            getErrorSensorInfo: "センサー情報が取得できませんでした",
            getErrorAccidentNoContent: "過去情報が存在しませんでした",
            getErrorAccidentInfo: "過去情報が取得できませんでした",
            getErrorCameraNoContent: "カメラ情報が存在しませんでした",
            getErrorCameraInfo: "カメラ情報が取得できませんでした",
            streamingErrorNotPlay: "ストリーミングが再生できませんでした",
            getErrorNearMissInfoVideoNoContent: "ヒヤリハット映像が存在しませんでした",
            getErrorNearMissInfoVideoInfo: "ヒヤリハット映像が取得できませんでした",
            getErrorNearMissDetailInfo: "ヒヤリハット詳細データが取得できませんでした",
            getErrorNearMissDetailInfoNoContent: "ヒヤリハット詳細データが存在しませんでした",
            getErrorMacroAnalysisInfo: "マクロ分析データが取得できませんでした",
            getErrorMacroAnalysisInfoNoContent: "マクロ分析データが存在しませんでした",
            getErrorMacroAnalysisDetailInfo: "マクロ分析詳細データが取得できませんでした",
            getErrorMacroAnalysisDetailInfoNoContent: "マクロ分析詳細データが存在しませんでした",
            getErrorMacroAnalysisInfoDataForExcel: "マクロ分析ダウンロードデータが取得できませんでした"
          }
        }
      },
      // S3バケットspits-video-bucket(過去事故情報映像格納場所)
      movieUrl:
        process.env.VUE_APP_WORKING_ENV === void 0 || process.env.VUE_APP_WORKING_ENV === "first"
          ? "https://d3k8hxujvgzdpf.cloudfront.net/"
          : "https://d3faiitsvruh4s.cloudfront.net/",
      wovenDir: "woven/",
      // S3バケットspits-video-strage-bucket(ヒヤリハット事故映像格納場所)
      videoStrageBucketUrl:
        process.env.VUE_APP_WORKING_ENV === void 0 || process.env.VUE_APP_WORKING_ENV === "first"
          ? "https://dlot612spnqqm.cloudfront.net/"
          : "https://d2axvzmk7achnf.cloudfront.net/",
      // S3バケットspits-download-bucket(マクロ分析データ(Excel)格納場所)
      spitsDownloadBucketUrl:
        process.env.VUE_APP_WORKING_ENV === void 0 || process.env.VUE_APP_WORKING_ENV === "first"
          ? "https://dq10ie6oos2r.cloudfront.net/"
          : "https://d2jxo394p4awj1.cloudfront.net/"
    };
  },
  methods: {
    /**
     * /assets/setting/setting.jsonの値をJSONobject形式で取得
     * @returns {Object} setting.jsonの値
     */
    getSetting() {
      return JSON.parse(JSON.stringify(settingJson));
    },

    /**
     * ルートURL生成
     * @param {String} path router\index.jsに定義済みのパス
     * @returns {String} Rootomain + path
     */
    getRouterPath(path) {
      return settingJson.common.baseUrl + path;
    },

    /**
     * センサー機器種別一覧取得
     * @returns {Array[Object]} センサー機器種別一覧
     */
    getSensorSettingList() {
      let sensorList = [];
      let list = this.$store.state.selectPole.sensorList;
      if (list.length == 0) {
        return sensorList;
      }
      list.sort(function(a, b) {
        if (a.sensorId < b.sensorId) return -1;
      });
      let sensorKind = undefined;
      let index = 0;
      let isFarst = true;
      list.forEach(sensor => {
        let setting = this.getSensorSetting(sensor.sensorId);
        if (setting != undefined) {
          if (sensorKind != setting.kind) {
            if (isFarst) {
              isFarst = false;
            } else {
              index += 1;
            }
            sensorList.push({
              id: index,
              name: setting.name,
              children: []
            });
            sensorKind = setting.kind;
          }
          let leftPadding = "000000";
          let hexSensorId = leftPadding + sensor.sensorId.toString(16);
          hexSensorId = hexSensorId.slice(-6);
          let idData = {
            name: hexSensorId,
            value: String(sensor.sensorId),
            color: setting.color,
            kind: setting.name
          };
          sensorList[index].children.push({
            id: JSON.stringify(idData),
            name: hexSensorId
          });
        }
      });
      return sensorList;
    },

    /**
     * センサー機器種別情報取得
     * @param {Number} sensorId センサーID
     * @returns {Object or undefined} センサー機器種別情報
     */
    getSensorSetting(sensorId) {
      const sensorList = settingJson.common.sensorList;
      let sensorSetting = undefined;
      let sensorKind = sensorId >> 16;
      for (const item of sensorList) {
        if (sensorKind == item.kind) {
          sensorSetting = item;
          break;
        }
      }
      return sensorSetting;
    },

    /**
     * カメラ機器種別一覧情報取得
     * @returns {Array[Object]} カメラ機器種別一覧
     */
    getCameraList() {
      let cameralist = [];
      let list = this.$store.state.selectPole.sensorList;
      if (list.length == 0) {
        return cameralist;
      }
      let settingCameraList = settingJson.common.cameraList;
      let sensorList = [];
      list.forEach(sensor => {
        let sensorKind = sensor.sensorId >> 16;
        settingCameraList.forEach(camera => {
          if (camera.kind == sensorKind) {
            sensorList.push({
              sensorId: sensor.sensorId,
              poleId: this.$store.state.selectPole.poleId,
              cameraKind: camera.kind
            });
          }
        });
      });
      if (sensorList.length == 0) {
        return cameralist;
      }
      sensorList.sort(function(a, b) {
        if (a.cameraKind < b.cameraKind) return -1;
      });
      let count = 0;
      let oldKind = undefined;

      sensorList.forEach(camera => {
        if (oldKind != camera.cameraKind) {
          oldKind = undefined;
          count = 0;
        }
        let name = this.getCameraName(camera.cameraKind);
        const kindCount = sensorList.reduce((total, item) => total + (item.cameraKind == camera.cameraKind ? 1 : 0), 0);
        if (camera.cameraKind == 20) {
          let vehicleId = camera.sensorId & 255;
          name += " [Vheicle ID:" + vehicleId.toString() + "]";
        } else if (kindCount > 1) {
          count += 1;
          name += " " + count.toString();
        }
        cameralist.push({
          sensorId: camera.sensorId,
          poleId: camera.poleId,
          cameraKind: camera.cameraKind,
          name: name
        });
        oldKind = camera.cameraKind;
      });
      return cameralist;
    },

    /**
     * カメラ機器種別名取得
     * @param {Number} kind カメラ機器種別ID
     * @returns {String} カメラ機器種別名
     */
    getCameraName(kind) {
      let name = "undefined";
      const cameraList = settingJson.common.cameraList;
      for (const item of cameraList) {
        if (kind == item.kind) {
          name = item.name;
          break;
        }
      }
      return name;
    },

    /**
     * 映像種別一覧取得
     * @param {Number} kind 映像種別ID
     * @returns {String} 映像種別名
     */
    getMovieName(kind) {
      let name = "undefined";
      const movieList = settingJson.common.movieList;
      for (const item of movieList) {
        if (kind == item.kind) {
          name = item.name;
          break;
        }
      }
      return name;
    },

    /**
     * 物標オブジェクト名取得
     * @param {Number} kind 物標オブジェクトID
     * @returns {String} 物標オブジェクト名
     */
    getObjectName(kind) {
      const objectInfoList = settingJson.common.objectInfoList;
      let name = "その他";
      for (const item of objectInfoList) {
        if (kind == item.kind) {
          name = item.name;
          break;
        }
      }
      return name;
    },

    /**
     * 車両ID付き物標オブジェクト名取得
     * @param {Number} vehicleId 車両ID
     * @param {Number} kind 物標オブジェクトID
     * @returns {String} 車両ID付き物標オブジェクト名
     */
    getObjectNameWithVehicleId(vehicleId, kind) {
      const objectInfoList = settingJson.common.objectInfoList;
      let name = "(" + vehicleId + ")" + "その他";
      for (const item of objectInfoList) {
        if (kind == item.kind) {
          name = "(" + vehicleId + ")" + item.name;
          break;
        }
      }
      return name;
    },

    /**
     * 車両のシフトポジション情報取得
     * @param {Number} state
     * @returns {String} 車両のシフトポジション情報(N：ニュートラル P：パーキング D：ドライブ R：リバース 4～6：予約 -：不定)
     */
    getShiftName(state) {
      const shiftNameList = settingJson.common.shiftNameList;
      let name = "-";
      for (const item of shiftNameList) {
        if (state == item.state) {
          name = item.name;
          break;
        }
      }
      return name;
    },

    /**
     * ポール情報設定
     * @param {Number} poleId ポールID
     * @param {String} address ポール地域情報
     */
    setSelectPole(poleId, address) {
      try {
        let sensorList = [];
        let latlng = undefined;
        let areaId = undefined;
        const poleList = this.$store.state.poleList;
        const poleInfo = this.$store.state.poleInfo;
        const selectPole = this.$store.state.selectPole;

        if (settingJson.common.server > 2) {
          poleId = 1;
          areaId = poleList[0].areaId;
          sensorList = poleList[0].sensorList;
          latlng = [poleList[0].latitude, poleList[0].longitude];
        } else {
          // 特定のポール情報が設定されていた場合、その内容を優先的に使用する
          if (poleInfo !== void 0 && poleInfo.length > 0) {
            areaId = poleInfo[0].areaId;
            sensorList = poleInfo[0].sensorList;
            latlng = [poleInfo[0].latitude, poleInfo[0].longitude];
          } else {
            // ポール一覧から対象のポールIDを持つ情報を取得する
            for (const item of poleList) {
              if (poleId === item.poleId) {
                areaId = item.areaId;
                sensorList = item.sensorList;
                latlng = [item.latitude, item.longitude];
                break;
              }
            }
          }
          if (areaId == undefined) {
            areaId = selectPole.areaId;
            latlng = selectPole.latlng;
          }
        }
        this.$store.dispatch(STORE_ACTIONS.setSelectPole, {
          poleId: poleId,
          areaId: areaId,
          latlng: latlng,
          address: address,
          sensorList: sensorList
        });
      } catch (error) {
        log.error("setSelectPole error " + error);
      }
    },

    /**
     * ログインユーザー情報設定
     * @param {Object} user ログインユーザー情報
     */
    setUserInfo(user) {
      try {
        this.$store.dispatch(STORE_ACTIONS.setUserInfo, user);
      } catch (error) {
        log.error("setUserInfo error " + error);
      }
    },

    /**
     * センサー一覧情報更新
     * @param {Object} oldPole 変更前のポール情報及びセンサー情報
     * @param {Object} newPole 変更対象のポール情報及びセンサー情報
     * @param {Function} updatePole pole情報更新メソッド
     * @param {Function} updateSensor 物標情報(センサー)更新メソッド
     */
    updateSensorList(oldPole, newPole, updatePole, updateSensor) {
      if (oldPole.latlng[0] != newPole.latlng[0] || oldPole.latlng[1] != newPole.latlng[1]) {
        updatePole();
      }
      if (this.checkSensorUpdate(oldPole, newPole)) {
        updateSensor();
      }
    },

    /**
     * 変更前のポール情報及びセンサー情報と変更対象のデータで差異があるかチェックする
     * @param {Object} oldPole 変更前のポール情報及びセンサー情報
     * @param {Object} newPole 変更対象のポール情報及びセンサー情報
     * @returns {Boolean} True:変更あり
     */
    checkSensorUpdate(oldPole, newPole) {
      if (oldPole.sensorList.length != newPole.sensorList.length) {
        return true;
      }
      if (newPole.sensorList.length == 0) {
        return false;
      }
      newPole.sensorList.sort(function(a, b) {
        if (a.sensorId < b.sensorId) return -1;
      });
      oldPole.sensorList.sort(function(a, b) {
        if (a.sensorId < b.sensorId) return -1;
      });
      for (let i = 0; i < newPole.sensorList.length; i++) {
        if (newPole.sensorList[i].sensorId != oldPole.sensorList[i].sensorId) {
          return true;
        }
        if (newPole.sensorList[i].latitude != oldPole.sensorList[i].latitude) {
          return true;
        }
        if (newPole.sensorList[i].longitude != oldPole.sensorList[i].longitude) {
          return true;
        }
        if (newPole.sensorList[i].detectDirectionMin != oldPole.sensorList[i].detectDirectionMin) {
          return true;
        }
        if (newPole.sensorList[i].detectDirectionMax != oldPole.sensorList[i].detectDirectionMax) {
          return true;
        }
        if (newPole.sensorList[i].detectDistanceMin != oldPole.sensorList[i].detectDistanceMin) {
          return true;
        }
        if (newPole.sensorList[i].detectDistanceMax != oldPole.sensorList[i].detectDistanceMax) {
          return true;
        }
      }
      return false;
    },

    /**
     * カメラ機器種別一覧情報
     * @param {Object} oldCamera 変更前のカメラ機器種別一覧情報
     * @param {Object} newCamera 変更対象のカメラ機器種別一覧情報
     * @param {Function} updateCamera カメラ機器種別一覧更新
     */
    updateCameraList(oldCamera, newCamera, updateCamera) {
      if (this.checkCameraList(oldCamera, newCamera)) {
        updateCamera();
      }
    },

    /**
     * 変更前のカメラ機器種別一覧と変更対象のデータで差異があるかチェックする
     * @param {Object} oldCamera 変更前のカメラ機器種別一覧情報
     * @param {Object} newCamera 変更対象のカメラ機器種別一覧情報
     * @returns {boolean} True:変更あり
     */
    checkCameraList(oldCamera, newCamera) {
      if (oldCamera.length != newCamera.length) {
        return true;
      }
      oldCamera.sort(function(a, b) {
        if (a.sensorId < b.sensorId) return -1;
      });
      newCamera.sort(function(a, b) {
        if (a.sensorId < b.sensorId) return -1;
      });
      for (let i = 0; i < newCamera.length; i++) {
        if (newCamera[i].sensorId != oldCamera[i].sensorId) {
          return true;
        }
      }
      return false;
    },

    /**
     * 選択済みセンサー種別一覧更新
     * @param {Array[Object]} selectSensor 選択済みセンサー一覧
     * @param {Array[Object]} sensorList 選択可能なセンサー一覧
     * @returns
     */
    updateSelectSensor(selectSensor, sensorList) {
      let list = [];
      selectSensor.forEach(sensor1 => {
        sensorList.forEach(sensor => {
          for (const sensor2 of sensor.children) {
            let obj = JSON.parse(sensor2.id);
            if (sensor1.value == obj.value && !list.some(v => v.value == sensor1.value)) {
              list.push(sensor1);
            }
          }
        });
      });
      return list;
    },

    /**
     * S3バケットspits-video-bucket(過去事故情報映像格納場所)URL取得
     * @returns {String}
     */
    getMovieUrl() {
      return this.movieUrl;
    },

    /**
     * S3バケットspits-video-bucket(過去事故情報映像格納場所)認証情報取得
     * @param {String} movieToken
     * @param {String} path
     * @returns {String} 認証情報
     */
    getMovieCredential(movieToken, path) {
      let credentials = movieToken.notwoven_credentials;
      if (path.startsWith(this.wovenDir)) {
        credentials = movieToken.woven_credentials;
      }
      return credentials;
    },

    /**
     * サマリー速度表記取得
     * @returns {Object} サマリー速度表記
     */
    getSummarySpeed() {
      return settingJson.common.summarySpeed;
    },

    /**
     * S3バケットspits-video-strage-bucket(ヒヤリハット事故映像格納場所)URL取得
     * @returns
     */
    getVideoStrageBucketUrl() {
      return this.videoStrageBucketUrl;
    },

    /**
     * S3バケットspits-download-bucket(マクロ分析データ(Excel)格納場所)URL取得
     * @returns
     */
    getSpitsDownloadBucketUrl() {
      return this.spitsDownloadBucketUrl;
    },
    /**
     * 1st、2nd環境で、設定するメニュー項目を変更する
     * @param {Object} menuList 対象の画面におけるメニュー一覧
     * @return {Object} 表示させるメニュー一覧
     */
    setMenuList(menuList) {
      let response = [];
      for (let menu of menuList) {
        // 1st環境でのみ適用する項目の場合、接続先が1st環境ならメニュー項目に追加する
        if (process.env.VUE_APP_WORKING_ENV !== void 0) {
          if (process.env.VUE_APP_WORKING_ENV === "first" && menu.extended) {
            response.push(menu);
          }
        }
        // 1st、2ndともに必須の項目を追加する
        if (!menu.extended) {
          response.push(menu);
        }
      }
      return response;
    }
  }
};

/**
 * 物標表示関連処理
 */
export const mapFunction = {
  data() {
    return {
      // 各物標関連の画像データ
      accidentIcon: require("@/assets/img/map/accident.png"),
      directionIcon: require("@/assets/img/object/direction.svg"),
      steeringIcon: require("@/assets/img/object/steering.svg"),
      markerList: [
        {
          kind: 0,
          objectMarker: require("@/assets/img/object/car.svg"),
          mapMarker: {
            select: require("@/assets/img/object/car.svg"),
            default: require("@/assets/img/object/car.svg")
          }
        },
        {
          kind: 1,
          objectMarker: require("@/assets/img/object/car.svg"),
          mapMarker: {
            select: require("@/assets/img/object/car.svg"),
            default: require("@/assets/img/object/car.svg")
          }
        },
        {
          kind: 2,
          objectMarker: require("@/assets/img/object/car.svg"),
          mapMarker: {
            select: require("@/assets/img/object/car.svg"),
            default: require("@/assets/img/object/car.svg")
          }
        },
        {
          kind: 3,
          objectMarker: require("@/assets/img/object/bike.svg"),
          mapMarker: {
            select: require("@/assets/img/object/bike.svg"),
            default: require("@/assets/img/object/bike.svg")
          }
        },
        {
          kind: 4,
          objectMarker: require("@/assets/img/object/bicycle.svg"),
          mapMarker: {
            select: require("@/assets/img/object/bicycle.svg"),
            default: require("@/assets/img/object/bicycle.svg")
          }
        },
        {
          kind: 5,
          objectMarker: require("@/assets/img/object/question.svg"),
          mapMarker: {
            select: require("@/assets/img/object/question.svg"),
            default: require("@/assets/img/object/question.svg")
          }
        },
        {
          kind: 6,
          objectMarker: require("@/assets/img/object/human.svg"),
          mapMarker: {
            select: require("@/assets/img/object/human.svg"),
            default: require("@/assets/img/object/human.svg")
          }
        },
        {
          kind: 7,
          objectMarker: require("@/assets/img/object/train.svg"),
          mapMarker: {
            select: require("@/assets/img/object/train.svg"),
            default: require("@/assets/img/object/train.svg")
          }
        },
        {
          kind: 15,
          objectMarker: require("@/assets/img/object/question.svg"),
          mapMarker: {
            select: require("@/assets/img/object/question.svg"),
            default: require("@/assets/img/object/question.svg")
          }
        }
      ],
      selectMarkerIcon: {
        iconUrl: require("@/assets/img/marker/marker-icon-red.png"),
        iconRetinaUrl: require("@/assets/img/marker/marker-icon-2x-red.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        shadowSize: [0, 0]
      },
      defaultMarkerIcon: {
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        shadowSize: [0, 0]
      },
      ledMarkerIcon: {
        iconUrl: require("@/assets/img/marker/marker-icon-2x-violet.png"),
        iconRetinaUrl: require("@/assets/img/marker/marker-icon-2x-violet.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        iconSize: [27, 43],
        iconAnchor: [20, 41],
        shadowSize: [0, 0]
      },
      ledMarkerList: {
        // 安全運転支援
        safeDrivingSupport: [
          {
            kind: "-01",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "種別不明"
          },
          {
            kind: "001",
            objectMarker: require("@/assets/img/object/human.svg"),
            definition: "歩行者"
          },
          {
            kind: "002",
            objectMarker: require("@/assets/img/object/bicycle.svg"),
            definition: "自転車"
          },
          {
            kind: "003",
            objectMarker: require("@/assets/img/object/bike.svg"),
            definition: "自動二輪車"
          },
          {
            kind: "004",
            objectMarker: require("@/assets/img/object/car.svg"),
            definition: "自動車"
          },
          {
            kind: "005",
            objectMarker: require("@/assets/img/object/car.svg"),
            definition: "自動運転車両"
          },
          {
            kind: "006",
            objectMarker: require("@/assets/img/object/bus.svg"),
            definition: "バス"
          },
          {
            kind: "100",
            objectMarker: require("@/assets/img/object/ambulance.svg"),
            definition: "緊急車両"
          },
          {
            kind: "-10",
            objectMarker: "",
            definition: "なし"
          }
        ],
        safeDrivingSupportOthers: {
          objectMarker: "",
          definition: "その他：予約"
        },
        // 災害危機情報通知
        // TODO アイコン設定
        disaster: [
          {
            kind: "199",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "種別不明"
          },
          {
            kind: "101",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "地震"
          },
          {
            kind: "102",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "津波"
          },
          {
            kind: "103",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "火山"
          },
          {
            kind: "104",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "台風"
          },
          {
            kind: "105",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "大雨"
          },
          {
            kind: "106",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "竜巻"
          },
          {
            kind: "107",
            objectMarker: require("@/assets/img/object/question.svg"),
            definition: "J アラート （弾道ミサイル）"
          },
          {
            kind: "-10",
            objectMarker: "",
            definition: "なし"
          }
        ],
        disasterOthers: {
          objectMarker: "",
          definition: "その他：予約"
        }
      }
    };
  },
  methods: {
    /**
     * マーカーアイコンオブジェクト取得
     * @param {Boolean} select
     * @returns {Object} マーカーアイコンオブジェクト
     */
    getMarkerIcon(select) {
      if (select) {
        return this.selectMarkerIcon;
      } else {
        return this.defaultMarkerIcon;
      }
    },
    /**
     * LEDマーカーアイコンオブジェクト取得
     * @returns {Object} マーカーアイコンオブジェクト
     */
    getLedMarkerIcon() {
      return this.ledMarkerIcon;
    },

    /**
     * 物標アイコンオブジェクト取得
     * @param {Number} kind 物標アイコン種別ID
     * @returns {Object} 物標アイコンオブジェクト
     */
    getObjectMarker(kind) {
      let marker = require("@/assets/img/object/question.svg");
      for (const item of this.markerList) {
        if (kind == item.kind) {
          marker = item.objectMarker;
          break;
        }
      }
      return marker;
    },

    /**
     * LED表示版アイコンオブジェクト取得
     * @param {String} dispKind LED表示内容種別
     * @param {Object} objKind LED表示版アイコンオブジェクト
     * @returns {Object} objKind LED表示版アイコンオブジェクト
     */
    getLedObjectMarker(dispKind, objKind) {
      // 安全運転支援
      const safeDrivingSupportDispKind = "001";
      // 災害
      const disasterDispKind = "007";
      let marker = null;
      let targetList = null;

      if (dispKind == safeDrivingSupportDispKind) {
        targetList = this.ledMarkerList.safeDrivingSupport;
      } else if (dispKind == disasterDispKind) {
        targetList = this.ledMarkerList.disaster;
      }
      if (targetList != null) {
        for (const item of targetList) {
          if (objKind == item.kind) {
            marker = item.objectMarker;
            break;
          }
        }
      }
      return marker;
    },

    /**
     * 物標アイコン画像データオブジェクト取得
     * @param {Number} kind 物標アイコン種別ID
     * @param {Boolean} select
     * @returns {Object} 物標アイコン画像データオブジェクト
     */
    getMapMarker(kind, select) {
      let marker;
      if (select) {
        marker = require("@/assets/img/map/question_select.svg");
      } else {
        marker = require("@/assets/img/map/question.svg");
      }
      for (const item of this.markerList) {
        if (kind == item.kind) {
          let mapMarker = item.mapMarker;
          if (select) {
            marker = mapMarker.select;
          } else {
            marker = mapMarker.default;
          }
          break;
        }
      }
      return marker;
    },

    /**
     * マップ(地図上の)マーカー情報取得
     * @param {Number} id 選択済みアイコンの識別ID
     * @param {Object} markers マップ(地図上の)マーカーオブジェクト情報
     * @returns
     */
    getMarkerInfo(id, markers) {
      let markerInfo = undefined;
      for (const item of markers) {
        if (id == item.id) {
          markerInfo = item;
          break;
        }
      }
      return markerInfo;
    },
    /**
     * 物標マーカーの一覧を返す
     * @returns {Object} 物標マーカー一覧
     */
    getMarkerList() {
      return this.ledMarkerList.safeDrivingSupport;
    },

    /**
     * マップ(地図)オブジェクト情報取得
     * @param {Object} map マップオブジェクト
     * @param {Number} maxZoom 最大拡大率
     * @param {Number} maxNativeZoom 背景地図の既存zoomレベル
     * @returns {Object} マップ(地図)オブジェクト情報
     */
    getTileLayer(map, maxZoom, maxNativeZoom) {
      if (maxNativeZoom != undefined) {
        return {
          OpenStreetMap: L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: 'Map data © <a href="https://openstreetmap.org/">OpenStreetMap</a>',
            maxZoom: maxZoom,
            maxNativeZoom: maxNativeZoom
          }).addTo(map),
          "国土地理院(地図)": L.tileLayer("https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png", {
            attribution: 'Map data © <a href="http://www.gsi.go.jp/kikakuchousei/kikakuchousei40182.html">国土地理院</a>',
            maxZoom: maxZoom,
            maxNativeZoom: maxNativeZoom
          }),
          "国土地理院(写真)": L.tileLayer("https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg", {
            attribution: 'Map data © <a href="http://www.gsi.go.jp/kikakuchousei/kikakuchousei40182.html">国土地理院</a>',
            maxZoom: maxZoom,
            maxNativeZoom: maxNativeZoom
          })
        };
      } else {
        return {
          OpenStreetMap: L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: 'Map data © <a href="https://openstreetmap.org/">OpenStreetMap</a>',
            maxZoom: maxZoom
          }).addTo(map),
          "国土地理院(地図)": L.tileLayer("https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png", {
            attribution: 'Map data © <a href="http://www.gsi.go.jp/kikakuchousei/kikakuchousei40182.html">国土地理院</a>',
            maxZoom: maxZoom
          }),
          "国土地理院(写真)": L.tileLayer("https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg", {
            attribution: 'Map data © <a href="http://www.gsi.go.jp/kikakuchousei/kikakuchousei40182.html">国土地理院</a>',
            maxZoom: maxZoom
          })
        };
      }
    }
  }
};

/**
 * 非同期処理
 */
export const timerFunction = {
  data() {
    return {
      posListWithPoleTimer: undefined
    };
  },
  methods: {
    /**
     * 非同期処理開始関数
     * @param {Number} poleId ポールID
     * @param {Number} interval 実行間隔(秒)
     * @param {Function} callback 実行処理
     */
    startPosListWithPoleUpdateTimer(poleId, interval, callback) {
      this.stopPosListWithPoleUpdateTimer();

      this.posListWithPoleTimer = setInterval(function() {
        callback(poleId);
      }, interval);
    },

    /**
     * 非同期処理終了関数
     */
    stopPosListWithPoleUpdateTimer() {
      if (this.posListWithPoleTimer != undefined) {
        clearInterval(this.posListWithPoleTimer);
        this.posListWithPoleTimer = undefined;
      }
    }
  }
};

/**
 * 外部機能接続共通処理
 */
export const communicationFunction = {
  data() {
    // APIルートリソース
    const rootResource = process.env.VUE_APP_WORKING_ENV === void 0 || process.env.VUE_APP_WORKING_ENV === "first" ? "/spits_server/" : "/sp1_spits_server/";
    return {
      // 各APIドメイン定義(aws-exports.js)参照
      api: {
        server: "spitsServer",
        serverDevelop: "spitsServerDev",
        serverCloud: "spitsServerCloud",
        serverLocal: "spitsServerLocal",
        gateway: "getSignedUrl",
        spitsAdminApiUrl: "spitsAdminApiUrl"
      },

      // APIリソース
      url: {
        // S3バケットspits-video-bucket(過去事故情報映像格納場所)アクセス認証
        signedUrl:
          process.env.VUE_APP_WORKING_ENV === void 0 || process.env.VUE_APP_WORKING_ENV === "first" ? "/default/create_cf_signed_url" : "/sp1_default/create_cf_signed_url",
        // エリア情報
        areaInfo: rootResource + "getAreaInfo",
        // ポール情報
        poleInfo: rootResource + "getSmartPoleInfo",
        // 過去事故情報一覧
        historyInfo: rootResource + "getHistoryDataInfo",
        // 過去物標情報
        posInfoWithPole: rootResource + "getPositionInfoWithPole",
        // 最新物標情
        posInfoReal: rootResource + "getPositionInfoReal",
        // シグナリングチャネル名取得
        getSignalingChannel: rootResource + "getSignalingChannel",
        // ストリーミング開始要求
        streamingStart: rootResource + "streamingStartReq",
        // ストリーミング応答確認
        streamingChkResp: rootResource + "streamingChkResp",
        // ストリーミング再生中通知
        streamingActiveNotify: rootResource + "streamingActiveNotify",
        // ヒヤリハット情報
        nearMisses: rootResource + "getNearMissInfoByPoleId",
        // ヒヤリハット詳細情報
        nearMissDetailInfo: rootResource + "getNearMissDetailInfo",
        // ヒヤリハット、事故件数取得
        nearMissSummary: rootResource + "getNearMissSummary",
        // 事故情報
        accidentInfo: rootResource + "getAccidentInfoByPoleId",
        // 駐停車情報
        parkingInfo: rootResource + "getParkedInfoByPoleId",
        // 見守り情報
        watchingInfo: rootResource + "getDistanceFromPoleToVehicleByAreaId",
        // 日時サマリー情報
        summallyDaily: rootResource + "getDailySummariesByPoleId",
        // 週次サマリー情報
        summallyWeekly: rootResource + "getWeeklySummariesByPoleId",
        // マクロ分析情報
        macroAnalysisInfo: rootResource + "getMacroAnalysisInfo",
        // マクロ分析詳細情報
        macroAnalysisDetailInfo: rootResource + "getMacroAnalysisDetailInfo",
        // マクロ分析データ作成
        createMacroAnalysisInfoDataForExcel: rootResource + "createMacroAnalysisInfoDataForExcel",
        // マクロ分析データ作成ステータステーブルデータ情報
        getMacroAnalysisInfoDataForExcel: rootResource + "getMacroAnalysisInfoDataForExcel"
      },
      // エラーコード種別
      errcode: {
        noContent: 204,
        badRequest: 400,
        internalServerError: 500
      },
      // openstreetmap情報下記参照
      // https://kazuki-nagasawa.hatenablog.com/entry/openstreetmap_nominatim
      // https://nominatim.org/release-docs/latest/api/Reverse/
      address: {
        endpoint: "https://nominatim.openstreetmap.org/reverse",
        format: "jsonv2"
      },
      // ストリーミング状態種別
      streamingChkRespCode: {
        wait: 0,
        ok: 1,
        ng: 2
      }
    };
  },
  methods: {
    /**
     * 呼び出しサーバのDomain取得
     * @returns
     */
    getServer() {
      let server = undefined;
      if (settingJson.common.server == 0) {
        server = this.api.server;
      } else if (settingJson.common.server == 1) {
        server = this.api.serverDevelop;
      } else if (settingJson.common.server == 2) {
        server = this.api.serverCloud;
      } else {
        server = this.api.serverLocal;
      }
      return server;
    },
    // ============================================================
    // ↓↓↓↓ $store.stateの値を更新 ↓↓↓↓
    // ============================================================
    /**
     * S3バケットspits-video-bucket(過去事故情報映像格納場所)認証アクセストークン取得後,
     * @returns {Promise} API取得結果
     */
    async updateMovieToken() {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setUserMovieToken, undefined);
        try {
          const token = this.$store.state.userInfo.user.signInUserSession.idToken.jwtToken;
          const args = {
            headers: {
              Authorization: token
            }
          };
          await API.post(this.api.gateway, this.url.signedUrl, args)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setUserMovieToken, res.credentials);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateMovieToken error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updateMovieToken error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * エリア情報取得
     * @returns {Promise} API取得結果
     */
    async updateAreaList() {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setAreaList, []);
        try {
          const accessToken = this.$store.state.userInfo.user.signInUserSession.accessToken.jwtToken;
          const args = {
            headers: {
              "coginito-access-token": accessToken
            }
          };
          await API.get(this.getServer(), this.url.areaInfo, args)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setAreaList, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateAreaList error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updateAreaList error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * エリアに紐づくポール情報取得
     * @param {Number} areaId エリアID
     * @returns {Promise} API取得結果
     */
    async updatePoleListAsAreaId(areaId) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setPoleList, []);
        try {
          const accessToken = this.$store.state.userInfo.user.signInUserSession.accessToken.jwtToken;
          const args = {
            queryStringParameters: {
              areaId: areaId.toString()
            },
            headers: {
              "coginito-access-token": accessToken
            }
          };
          await API.get(this.getServer(), this.url.poleInfo, args)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setPoleList, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updatePoleListAsAreaId error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updatePoleListAsAreaId error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * エリアIDに紐づくポール情報の一覧を取得する
     * @param {Number} areaId エリアID
     * @returns ポール情報一覧
     */
    async getPoleListFromArea(areaId) {
      // APIアクセスのためのトークンとパラメータを設定
      const accessToken = this.$store.state.userInfo.user.signInUserSession.accessToken.jwtToken;
      const args = {
        queryStringParameters: {
          areaId: areaId.toString()
        },
        headers: {
          "coginito-access-token": accessToken
        }
      };
      // APIにポール情報一覧をリクエスト
      return API.get(this.getServer(), this.url.poleInfo, args)
        .then(res => {
          // 戻り値が存在していればその内容を返す。なければ例外を発生させる。
          if (res === void 0 || res === null) {
            throw new Error(this.errcode.noContent);
          } else {
            return res;
          }
        })
        .catch(error => {
          // 例外処理 エラーログを出力する
          if (error !== this.errcode.noContent) {
            log.error("updatePoleListAsAreaId error Can't call API");
          } else {
            log.error(error);
          }
          return null;
        });
    },

    /**
     * ポールIDに紐づくセンサー情報を取得
     * @param {Number} poleId ポールID
     * @param {String} datetime 対象日時 YYYY/MM/DD HH:mm:ss 形式
     * @returns {Promise} API取得結果
     */
    async updatePoleListAsPoleId(poleId, datetime) {
      return new Promise(async (resolve, reject) => {
        // this.$store.dispatch(STORE_ACTIONS.setPoleList, []);
        this.$store.dispatch(STORE_ACTIONS.setPoleInfo, []);
        try {
          const accessToken = this.$store.state.userInfo.user.signInUserSession.accessToken.jwtToken;
          let params = {
            queryStringParameters: {
              poleId: poleId.toString()
            },
            headers: {
              "coginito-access-token": accessToken
            }
          };
          if (datetime != undefined) {
            params.queryStringParameters.datetime = datetime;
          }
          await API.get(this.getServer(), this.url.poleInfo, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                // this.$store.dispatch(STORE_ACTIONS.setPoleList, res);
                this.$store.dispatch(STORE_ACTIONS.setPoleInfo, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updatePoleListAsPoleId error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updatePoleListAsPoleId error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },
    /**
     * ポールIDに紐づく過去物標情報取得後、ポール情報及び物標情報更新
     * @param {Number} poleId ポールID
     * @param {String} dateTime 対象日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {Boolean} isMultiCamera マルチ画面で必要なデータか判定
     * @returns {Promise} API取得結果
     */
    updatePosListWithPole(poleId, dateTime, isMultiCamera = false) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setPosList, []);
        this.$store.dispatch(STORE_ACTIONS.setPoleList, []);
        this.$store.dispatch(STORE_ACTIONS.setLedList, []);
        try {
          let params = {
            queryStringParameters: {
              poleId: poleId.toString(),
              diffTime: settingJson.common.posListDiffTime
            },
            headers: {
              "Cache-Control": "no-cache"
            }
          };
          if (dateTime != undefined) {
            params.queryStringParameters.dateTime = dateTime;
          }
          params.queryStringParameters.isMultiCamera = isMultiCamera;
          await API.get(this.getServer(), this.url.posInfoWithPole, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setPoleList, res.poleList);
                this.$store.dispatch(STORE_ACTIONS.setPosList, res.posList);
                this.$store.dispatch(STORE_ACTIONS.setLedList, res.ledList);
                resolve();
              }
            })
            .catch(error => {
              log.error("updatePosListWithPole error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updatePosListWithPole error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * ポールIDに紐づく最新物標情報取得後、物標情報更新
     * @param {Number} poleId ポールID
     * @param {String} sensorIds センサーID一覧
     * @returns {Promise} API取得結果
     */
    updatePosListReal(poleId, sensorIds) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setPosList, []);
        this.$store.dispatch(STORE_ACTIONS.setLedList, []);
        try {
          let params = {
            queryStringParameters: {
              poleId: poleId.toString(),
              difftime: settingJson.common.posListDiffTime,
              sensorIds: sensorIds.toString()
            },
            headers: {
              "Cache-Control": "no-cache"
            }
          };
          await API.get(this.getServer(), this.url.posInfoReal, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setPosList, res.posList);
                this.$store.dispatch(STORE_ACTIONS.setLedList, res.ledList);
                resolve();
              }
            })
            .catch(error => {
              log.error("updatePosListReal error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updatePosListReal error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },
    /**
     * 直近の物標情報を一覧取得する
     * @param {Number} id ポールID
     * @param {Number[]} sensorIdList 対象センサーID一覧
     * @param {Number} sec 最大で取得する過去の秒数
     * @return {Array} 物標情報一覧
     */
    async getLatestPositionList(id, sensorIdList, sec) {
      let params = {
        queryStringParameters: {
          poleId: id.toString(),
          difftime: sec,
          sensorIds: sensorIdList.toString()
        },
        headers: {
          "Cache-Control": "no-cache"
        }
      };
      return await API.get(this.getServer(), this.url.posInfoReal, params)
        .then(res => {
          if (res === void 0 || res == null || res.length === 0) {
            throw new Error(this.errcode.noContent);
          } else {
            return res.posList;
          }
        })
        .catch(error => {
          log.error("updatePosListReal error " + error);
        });
    },

    /**
     * ヒヤリハット情報取得
     * @param {Number} poleId ポールID
     * @param {Array} dates 日時 [YYYY/MM/DD,YYYY/MM/DD]
     * @param {String} fromTime 開始日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} toTime 終了日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {Number} threshold 閾値
     * @param {Boolean} isDeduplication 重複行排除可否
     * @param {Boolean} isRange 範囲検索可否
     *
     * @returns {Promise} API取得結果
     */
    updateNearMissesData(poleId, dates, fromTime, toTime, threshold, isDeduplication, isRange) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setNearMisses, []);
        try {
          let params = {
            queryStringParameters: {
              poleId: poleId.toString(),
              dates: dates.toString(),
              fromTime: fromTime,
              toTime: toTime,
              threshold: threshold.toString(),
              isDeduplication: isDeduplication,
              isRange: isRange
            }
          };
          await API.get(this.getServer(), this.url.nearMisses, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setNearMisses, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateNearMissesList error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updateNearMissesList error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },
    /**
     * ヒヤリハット件数取得
     * @param {Number} poleId ポールID
     * @param {Array} dates 日時 [YYYY/MM/DD,YYYY/MM/DD]
     * @param {String} fromTime 開始日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} toTime 終了日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {Number} threshold 閾値
     * @param {Boolean} isDeduplication 重複行排除可否
     * @param {Boolean} isRange 範囲検索可否
     * @returns ヒヤリハット件数
     */
    async getNearMissNumber(poleId, dates, fromTime, toTime, threshold, isDeduplication, isRange) {
      let params = {
        queryStringParameters: {
          poleId: poleId.toString(),
          dates: dates.toString(),
          fromTime: fromTime,
          toTime: toTime,
          threshold: threshold.toString(),
          isDeduplication: isDeduplication,
          isRange: isRange
        }
      };
      return await API.get(this.getServer(), this.url.nearMisses, params)
        .then(res => {
          if (res !== void 0 && res !== null) {
            return res.length;
          } else {
            throw new Error(this.errcode.noContent);
          }
        })
        .catch(error => {
          log.error("updateNearMissesList error " + error);
        });
    },
    /**
     * ヒヤリハット詳細情報取得
     * @param {Number} poleId ポールID
     * @param {Number} obj1Id 車両ID1
     * @param {Number} obj2Id 車両ID2
     * @param {String} occurredTime ヒヤリハット発生時刻 YYYY/MM/DD HH:mm:ss 形式
     * @returns {Promise} API取得結果
     */
    updateNearMissDetailInfo(poleId, obj1Id, obj2Id, occurredTime) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setPosList, []);
        try {
          let params = {
            queryStringParameters: {
              poleId: poleId.toString(),
              obj1Id: obj1Id.toString(),
              obj2Id: obj2Id.toString(),
              occurredTime: occurredTime
            }
          };
          await API.get(this.getServer(), this.url.nearMissDetailInfo, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setNearMissDetailInfo, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateNearMissDetailInfo error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updateNearMissDetailInfo error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },
    /**
     * ヒヤリハット、事故件数取得
     * @param {Number} areaId エリアID
     * @param {Number} poleId ポールID
     * @param {String} startTime 取得開始日時(YYYY/MM/DD HH:mm:ss)
     * @param {String} endTime 取得終了日時(YYYY/MM/DD HH:mm:ss)
     * @return {Promise} 取得処理自体を同期
     */
    async getNearMissSummary(areaId, poleId, startTime, endTime) {
      // 他の処理と同時に行うことを想定して非同期制御できるようにする
      return new Promise(async (resolve, reject) => {
        // Vuexストア上の件数保管用ステートをリセットする
        this.$store.dispatch(STORE_ACTIONS.setNearMissSummaryList, []);
        try {
          // APIに送るパラメータの整理
          let params = {
            queryStringParameters: {}
          };
          // 日時設定フラグ
          let startFlag = false;
          let endFlag = false;
          // パラメータとして設定されている値のみを追加する
          if (areaId !== void 0 && areaId !== null) {
            params.queryStringParameters["areaId"] = areaId;
          }
          if (poleId !== void 0 && poleId !== null) {
            params.queryStringParameters["poleId"] = poleId;
          }
          if (startTime !== void 0 && startTime !== null && startTime !== "") {
            params.queryStringParameters["startTime"] = startTime;
            startFlag = true;
          }
          if (endTime !== void 0 && endTime !== null && endTime !== "") {
            params.queryStringParameters["endTime"] = endTime;
            endFlag = true;
          }
          // 取得開始日時と終了日時が設定されている場合、指定順序が逆になっていないかを確認する
          if (startFlag && endFlag) {
            const timeFormat = "YYYY/MM/DD HH:mm:ss";
            const start = moment(startTime, timeFormat, true);
            const end = moment(endTime, timeFormat, true);
            // 逆になっている場合は400エラーを返す
            if (end.diff(start) < 0) {
              reject(this.errcode.badRequest);
            }
          }
          // AWS API Gatewayにアクセスして件数取得をリクエストする
          await API.get(this.getServer(), this.url.nearMissSummary, params)
            // レスポンスが返ってきてデータが含まれていれば、Vuexストアに登録する
            .then(res => {
              if (res === null) {
                // 204エラー
                reject(this.errcode.noContent);
              } else {
                // 正常
                this.$store.dispatch(STORE_ACTIONS.setNearMissSummaryList, res);
                resolve();
              }
            })
            .catch(error => {
              // 例外は500エラーとして処理する
              log.error("updateAccidentsData error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          // APIの呼び出しに失敗した場合、500エラーとして処理する
          log.error("updateAccidentsData error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * 事故情報取得
     * @param {Number} poleId ポールID
     * @param {Array} dates 日時 [YYYY/MM/DD,YYYY/MM/DD]
     * @param {String} fromTime 開始日時 HH:mm:ss 形式
     * @param {String} toTime 終了日時  HH:mm:ss 形式
     * @param {Boolean} isRange 範囲検索可否
     * @returns {Promise} API取得結果
     */
    async updateAccidentsData(poleId, dates, fromTime, toTime, isRange) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setAccidents, []);
        try {
          let params = {
            queryStringParameters: {
              poleId: poleId.toString(),
              dates: dates.toString(),
              fromTime: fromTime,
              toTime: toTime,
              isRange: isRange
            }
          };
          await API.get(this.getServer(), this.url.accidentInfo, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setAccidents, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateAccidentsData error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updateAccidentsData error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },
    /**
     * ポールに対して、対象時間中の事故発生件数を返す
     * @param {Number} poleId ポールID
     * @param {Array} dates 日時 [YYYY/MM/DD,YYYY/MM/DD]
     * @param {String} fromTime 開始日時 HH:mm:ss 形式
     * @param {String} toTime 終了日時  HH:mm:ss 形式
     * @param {Boolean} isRange 範囲検索可否
     * @returns 事故発生件数
     */
    async getAccidentNumber(poleId, dates, fromTime, toTime, isRange) {
      let params = {
        queryStringParameters: {
          poleId: poleId.toString(),
          dates: dates.toString(),
          fromTime: fromTime,
          toTime: toTime,
          isRange: isRange
        }
      };
      return await API.get(this.getServer(), this.url.accidentInfo, params)
        .then(res => {
          if (res !== void 0 && res !== null) {
            return res.length;
          } else {
            throw new Error(this.errcode.noContent);
          }
        })
        .catch(error => {
          log.error("updateAccidentsData error " + error);
        });
    },

    /**
     * 駐停車情報取得
     * @param {Number} poleId ポールID
     * @param {String} from 開始日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} to 終了日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {Number} threshold 閾値
     * @returns {Promise} API取得結果
     */
    async updateParkingData(poleId, from, to, threshold) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setParking, []);
        try {
          let params = {
            queryStringParameters: {
              poleId: poleId.toString(),
              from: from,
              to: to,
              threshold: threshold
            }
          };
          await API.get(this.getServer(), this.url.parkingInfo, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setParking, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateParkingData error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updateParkingData error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * 見守り情報取得
     * @param {Number} areaId
     * @param {String} from 開始日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} to 終了日時 YYYY/MM/DD HH:mm:ss 形式
     * @returns {Promise} API取得結果
     */
    async updateWatchingData(areaId, from, to) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setWatching, []);
        try {
          let params = {
            queryStringParameters: {
              areaId: areaId.toString(),
              from: from,
              to: to
            }
          };
          await API.get(this.getServer(), this.url.watchingInfo, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setWatching, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateParkingData error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updateParkingData error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * 日時サマリー情報取得
     * @param {Number} poleId
     * @param {String} summariesDate 対象日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {Number} nmaThr ヒヤリハット閾値
     * @param {Number} parkedThr 駐停車状況閾値
     * @returns {Promise} API取得結果
     */
    async updateSumariesDailyData(poleId, summariesDate, nmaThr, parkedThr) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setSumariesDailyList, []);
        try {
          let params = {
            queryStringParameters: {
              poleId: poleId.toString(),
              SummariesDate: summariesDate,
              nmaThr: nmaThr,
              parkedThr: parkedThr
            }
          };
          await API.get(this.getServer(), this.url.summallyDaily, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setSumariesDailyList, res[0]);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateSumariesDailyData error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updateSumariesDailyData error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * 週次サマリー情報取得
     * @param {Number} poleId
     * @param {String} summariesDate 対象日時 YYYY/MM/DD HH:mm:ss 形式
     * @param {Number} nmaThr ヒヤリハット閾値
     * @param {Number} parkedThr 駐停車状況閾値
     * @returns {Promise} API取得結果
     */
    async updateSumariesWeeklyData(poleId, summariesDate, nmaThr, parkedThr) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setSumariesWeeklyList, []);
        try {
          let queryParams = {
            queryStringParameters: {
              poleId: poleId.toString(),
              SummariesDate: summariesDate,
              nmaThr: nmaThr,
              parkedThr: parkedThr
            }
          };
          await API.get(this.getServer(), this.url.summallyWeekly, queryParams)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setSumariesWeeklyList, res[0]);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateSumariesWeeklyData error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("updateSumariesWeeklyData error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * 過去事故情報一覧取得
     * @param {Number} poleId
     * @returns {Promise} API取得結果
     */
    async updateAccidentList(poleId) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setAccidentList, []);
        try {
          const accessToken = this.$store.state.userInfo.user.signInUserSession.accessToken.jwtToken;
          let params = {
            queryStringParameters: {},
            headers: {
              "coginito-access-token": accessToken
            }
          };
          if (poleId != undefined) {
            params.queryStringParameters.poleId = poleId.toString();
          }
          await API.get(this.getServer(), this.url.historyInfo, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setAccidentList, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateAccidentList error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch (e) {
          log.error("updateAccidentList error Can't call API" + e);
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * マクロ分析情報取得
     * @param {Number} poleId ポールID
     * @param {String} aggregationPeriodStart 集計期間A 開始日 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} aggregationPeriodEnd 集計期間A 終了日 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} comparisonPeriodStart 比較期間B 開始日 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} comparisonPeriodEnd 比較期間B 終了日 YYYY/MM/DD HH:mm:ss 形式
     * @param {string} direction 方路
     * @param {string} led LED
     * @param {string} aggregationTarget 集計対象
     * @param {string} aggregationMethod 集計方法
     * @returns {Promise} API取得結果
     */
    updateMacroAnalysisInfo(
      poleId,
      aggregationPeriodStart,
      aggregationPeriodEnd,
      comparisonPeriodStart,
      comparisonPeriodEnd,
      direction,
      led,
      aggregationTarget,
      aggregationMethod
    ) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisInfo, {});
        try {
          let params = {
            queryStringParameters: {
              poleId: poleId.toString(),
              aggregationPeriodStart: aggregationPeriodStart,
              aggregationPeriodEnd: aggregationPeriodEnd,
              comparisonPeriodStart: comparisonPeriodStart,
              comparisonPeriodEnd: comparisonPeriodEnd,
              direction: direction,
              led: led,
              aggregationTarget: aggregationTarget,
              aggregationMethod: aggregationMethod
            }
          };
          await API.get(this.getServer(), this.url.macroAnalysisInfo, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisInfo, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateMacroAnalysisInfo error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch (e) {
          log.error("updateMacroAnalysisInfo error Can't call API" + e);
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * マクロ分析詳細情報取得
     * @param {String} section 区間
     * @param {Number} poleId ポールID
     * @param {String} aggregationPeriodStart 集計期間A 開始日 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} aggregationPeriodEnd 集計期間A 終了日 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} comparisonPeriodStart 比較期間B 開始日 YYYY/MM/DD HH:mm:ss 形式
     * @param {String} comparisonPeriodEnd 比較期間B 終了日 YYYY/MM/DD HH:mm:ss 形式
     * @param {string} direction 方路
     * @param {string} led LED
     * @param {string} aggregationTarget 集計対象
     * @param {string} aggregationMethod 集計方法
     * @returns {Promise} API取得結果
     */
    updateMacroAnalysisDetaiInfo(
      section,
      poleId,
      aggregationPeriodStart,
      aggregationPeriodEnd,
      comparisonPeriodStart,
      comparisonPeriodEnd,
      direction,
      led,
      aggregationTarget,
      aggregationMethod
    ) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisDetaiInfo, {});
        try {
          let params = {
            queryStringParameters: {
              section: section,
              poleId: poleId.toString(),
              aggregationPeriodStart: aggregationPeriodStart,
              aggregationPeriodEnd: aggregationPeriodEnd,
              comparisonPeriodStart: comparisonPeriodStart,
              comparisonPeriodEnd: comparisonPeriodEnd,
              direction: direction,
              led: led,
              aggregationTarget: aggregationTarget,
              aggregationMethod: aggregationMethod
            }
          };
          await API.get(this.getServer(), this.url.macroAnalysisDetailInfo, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisDetaiInfo, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateNearMissDetailInfo error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch (e) {
          log.error("updateNearMissDetailInfo error Can't call API" + e);
          reject(this.errcode.internalServerError);
        }
      });
    },
    /**
     * マクロ分析データ作成ステータステーブルデータ取得
     * @param {Number} poleId
     * @returns {Promise} API取得結果
     */
    updateMacroAnalysisInfoDataForExcel(poleId) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisInfoDataForExcel, []);
        try {
          const accessToken = this.$store.state.userInfo.user.signInUserSession.accessToken.jwtToken;
          let params = {
            queryStringParameters: {
              poleId: poleId.toString()
            },
            headers: {
              "coginito-access-token": accessToken
            }
          };
          await API.get(this.getServer(), this.url.getMacroAnalysisInfoDataForExcel, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisInfoDataForExcel, res);
                resolve();
              }
            })
            .catch(error => {
              log.error("updateMacroAnalysisInfoDataForExcel error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch (e) {
          log.error("updateMacroAnalysisInfoDataForExcel error Can't call API" + e);
          reject(this.errcode.internalServerError);
        }
      });
    },
    // ============================================================
    // ↑↑↑↑ $store.stateの値を更新 ↑↑↑↑
    // ============================================================
    // TODO 確認
    createMacroAnalysisInfoDataForExcel(
      cityAddress,
      poleId,
      aggregationPeriodStart,
      aggregationPeriodEnd,
      comparisonPeriodStart,
      comparisonPeriodEnd,
      direction,
      led,
      aggregationTarget,
      aggregationMethod
    ) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch(STORE_ACTIONS.setMacroAnalysisDetaiInfo, {});
        try {
          const accessToken = this.$store.state.userInfo.user.signInUserSession.accessToken.jwtToken;
          const args = {
            body: {
              cityAddress: cityAddress,
              poleId: poleId.toString(),
              aggregationPeriodStart: aggregationPeriodStart,
              aggregationPeriodEnd: aggregationPeriodEnd,
              comparisonPeriodStart: comparisonPeriodStart,
              comparisonPeriodEnd: comparisonPeriodEnd,
              direction: direction,
              led: led,
              aggregationTarget: aggregationTarget,
              aggregationMethod: aggregationMethod
            },
            headers: {
              "coginito-access-token": accessToken
            }
          };
          await API.post(this.getServer(), this.url.createMacroAnalysisInfoDataForExcel, args)
            .then(() => {
              resolve();
            })
            .catch(error => {
              log.error("updateNearMissDetailInfo error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch (e) {
          log.error("updateNearMissDetailInfo error Can't call API" + e);
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * シグナルチャネル名を取得する
     * @param {Number} poleId ポールID
     * @param {Number} sensorId センサーID
     * @returns {Promise} 非同期化取得処理
     */
    async getSignalingChannel(poleId, sensorId) {
      return new Promise(async (resolve, reject) => {
        try {
          // パラメータを設定
          const params = {
            queryStringParameters: {
              poleId: poleId,
              sensorId: sensorId
            }
          };
          await API.get(this.getServer(), this.url.getSignalingChannel, params)
            .then(res => {
              // 戻り値が返ってきても内容がなければエラー処理をする
              if (res === void 0 || res === null) {
                reject(this.errcode.noContent);
              } else {
                // 戻り値が存在するなら正常終了する
                resolve(res);
              }
            })
            .catch(error => {
              // API呼び出し時の例外処理
              log.error("streamingStart error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          // API呼び出しに失敗
          log.error("streamingStart error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * ストリーミング開始要求送信
     * @param {Number} poleId ポールID
     * @param {Number} sensorId センサーID
     * @returns {Promise} API取得結果
     */
    async streamingStart(poleId, sensorId) {
      return new Promise(async (resolve, reject) => {
        try {
          const params = {
            queryStringParameters: {
              poleId: poleId,
              sensorId: sensorId
            }
          };
          await API.post(this.getServer(), this.url.streamingStart, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                resolve(res);
              }
            })
            .catch(error => {
              log.error("streamingStart error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("streamingStart error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * ストリーミング応答確認情報取得
     * @param {String} channel KVSチャネル名
     * @returns {Promise} API取得結果
     */
    async streamingChkResp(channel) {
      return new Promise(async (resolve, reject) => {
        try {
          const params = {
            queryStringParameters: {
              channel: channel
            }
          };
          await API.get(this.getServer(), this.url.streamingChkResp, params)
            .then(res => {
              if (res == null) {
                reject(this.errcode.noContent);
              } else {
                resolve(res);
              }
            })
            .catch(error => {
              log.error("streamingChkResp error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("streamingChkResp error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     *  ストリーミング再生中通知送信
     * @param {String} channel KVSチャネル名
     * @returns {Promise} API取得結果
     */
    async streamingActiveNotify(channel) {
      return new Promise(async (resolve, reject) => {
        try {
          const params = {
            queryStringParameters: {
              channel: channel
            }
          };
          await API.post(this.getServer(), this.url.streamingActiveNotify, params)
            .then(() => {
              resolve();
            })
            .catch(error => {
              log.error("streamingActiveNotify error " + error);
              reject(this.errcode.internalServerError);
            });
        } catch {
          log.error("streamingActiveNotify error Can't call API");
          reject(this.errcode.internalServerError);
        }
      });
    },

    /**
     * openstreetmapから緯度・経度を指定し住所や地名を取得
     * @param {Number} lat 緯度
     * @param {Number} long 経度
     * @returns {Object} API取得結果
     */
    async addressByCoordinates(lat, long) {
      return await Vue.http.get(this.address.endpoint, {
        params: {
          format: this.address.format,
          lat: lat,
          lon: long
        }
      });
    }
  }
};

/**
 * 管理機能向け処理関数
 */
export const adminCommFunction = {
  data() {
    // 運用する環境に応じてURLを切り替える
    const rootResource = process.env.VUE_APP_WORKING_ENV === void 0 || process.env.VUE_APP_WORKING_ENV === "first" ? "/spits-admin-api/" : "/sp1_spits-admin-api/";
    return {
      api: {
        spitsAdminApiUrl: "spitsAdminApiUrl"
      },
      url: {
        checkLoginUser: rootResource + "check_login_user",
        createUser: rootResource + "create_user",
        editUserInfo: rootResource + "edit_user_info",
        deleteUserInfo: rootResource + "delete_user_info",
        getUserList: rootResource + "get_user_list",
        getGroupAndAuthorityList: rootResource + "get_group_and_authority_list",
        getUserPoleInfo: rootResource + "get_user_pole_info",
        getAdminPoleList: rootResource + "get_pole_list",
        getPoleSensorHistory: rootResource + "get_pole_sensor_history",
        updateUserPoleInfo: rootResource + "update_user_pole_info",
        updateAreaPoleErrorDetection: rootResource + "update_area_pole_error_detection"
      },
      errcode: {
        noContent: 204,
        responseError: 400,
        internalServerError: 500
      }
    };
  },
  methods: {
    // サーバー情報取得
    getAdminServer() {
      let server = this.api.spitsAdminApiUrl;
      return server;
    },
    /**
     * ポール情報一覧を取得する
     * @returns ポール情報一覧
     */
    async getAdminPoleList() {
      // Vuexストア内の一覧情報をリセットする
      this.$store.dispatch(STORE_ACTIONS.updateAdminPoleList, []);
      // リクエストヘッダにCognitoのアクセストークンを追加する
      const accessToken = this.$store.state.userInfo.user.signInUserSession.accessToken.jwtToken;
      const args = {
        headers: {
          "coginito-access-token": accessToken
        }
      };
      // APIアクセス処理を呼び出しもとに返す
      return new Promise(async (resolve, reject) => {
        await API.get(this.getAdminServer(), this.url.getAdminPoleList, args)
          // 取得に成功して戻り値が存在していればポール情報一覧をVuexストアに設定する
          .then(res => {
            if (res === void 0 || res === null) {
              throw new Error(this.errcode.noContent);
            } else {
              this.$store.dispatch(STORE_ACTIONS.updateAdminPoleList, res);
              resolve(res);
            }
          })
          .catch(error => {
            log.error("getPoleList error!!! : " + error);
            log.error(this.errcode.internalServerError);
            reject(this.errcode.internalServerError);
          });
      });
    },
    /**
     * センサーの状態変化一覧取得
     * @param {Number} poleId ポールID
     * @return {Array} ポールの状態変化一覧
     */
    async getPoleSensorHistory(poleId) {
      const accessToken = this.$store.state.userInfo.user.signInUserSession.accessToken.jwtToken;
      const args = {
        queryStringParameters: {
          poleId: poleId.toString(),
          isLatest: true
        },
        headers: {
          "content-type": "application/json",
          "x-api-key": "9F0h1w79011nfIz0lCxr56SjNIEGlxs8WICeZ5ve",
          "coginito-access-token": accessToken
        }
      };
      return await API.get(this.getAdminServer(), this.url.getPoleSensorHistory, args)
        .then(res => {
          if (res === void 0 || res === null) {
            throw new Error(this.errcode.noContent);
          } else {
            return res;
          }
        })
        .catch(error => {
          log.error("getPoleSensorHistory error!!! : " + error);
          log.error(this.errcode.internalServerError);
          return null;
        });
    }
  }
};
