<template>
  <div id="selectarea_view">
    <v-container fluid fill-height>
      <SelectArea ref="map" id="selectarea_map" align="center" :pointList="titleInfo.pointList" v-on:areaListCreated="areaListCreated" v-on:pageSelected="pageSelected" />
      <PageSelectDialog :dialog="dialog" v-on:dialogClose="dialogClose" />
    </v-container>
    <v-main>
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" v-on:selectPoint="selectPoint" v-on:enterPoint="enterPoint" />
    <Loading v-show="isLoading" />
  </div>
</template>

<script>
  import SelectArea from "@/components/SelectArea";
  import PageSelectDialog from "@/components/PageSelectDialog";
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import { commonFunction } from "@/mixins/utils";

  export default {
    name: "SelectAreaView",
    components: {
      PageSelectDialog,
      SelectArea,
      Title,
      Loading
    },
    mixins: [commonFunction],
    data() {
      return {
        setting: undefined,
        dialog: {
          isDialog: false,
          name: ""
        },
        titleInfo: {
          title: "検証一覧",
          show: {
            logout: true,
            point: false,
            menu: true
          },
          pointList: undefined,
          menuList: undefined
        },
        isLoading: true
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      initialize() {
        this.setting = this.getSetting();
        this.titleInfo.menuList = this.setting.menu.selectArea;
      },
      areaListCreated(areaList) {
        this.titleInfo.pointList = this.createPointList(areaList);
        this.isLoading = false;
      },
      selectPoint(data) {
        this.$refs.map.setCenter(data);
      },
      enterPoint(data) {
        this.$refs.map.enterPoint(data);
      },
      createPointList(areaList) {
        let pointList = [];
        areaList.forEach(area => {
          pointList.push({
            id: area.areaId,
            name: area.name,
            latlng: [area.latitude, area.longitude]
          });
        });
        return pointList;
      },
      pageSelected(title) {
        this.dialog.name = title;
        this.dialog.isDialog = true;
      },
      dialogClose() {
        this.dialog.isDialog = false;
      }
    }
  };
</script>

<style>
  #selectarea_map {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
