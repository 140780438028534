<template>
  <div class="realtime_view">
    <v-container fluid fill-height>
      <v-row dense>
        <v-col cols="6">
          <!-- カメラ映像ブロック -->
          <v-card id="realtime_camera" elevation="5" outlined :max-height="'65vh'">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 6vh">
              <span class="py-0">カメラ映像</span>
              <v-spacer />
              <v-select
                dark
                v-model="selectCamera"
                :items="cameraList"
                item-text="name"
                item-value="channel"
                label="カメラ選択"
                autowidth
                return-object
                style="width: 10vw; height: 6vh"
              />
            </v-card-title>
            <v-divider />
            <div align="center">
              <RealTimeVideo v-if="showCamera" :camera="selectCamera" />
              <div id="realtime_logo" v-if="!showCamera"></div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card id="realtime_vmap" elevation="5" outlined :max-height="'65vh'">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 6vh">
              <span class="py-0">VIRTUAL映像</span>
              <SelectSensor :sensorList="sensorList" :sensorTemp="selectSensorTemp" @setUpSelectSensor="setUpSelectSensor" classCom="realtimeCom" classTree="realtimeTree" />
            </v-card-title>
            <v-divider />
            <VirtualMap ref="virtual_map" v-if="vMapEnabled" :accident="false" :selectSensor="selectSensor" align="center" isRealTime="True" />
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <!-- 交差点内物標情報 -->
          <v-card id="realtime_location" elevation="5" outlined>
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 4vh">
              <span class="py-0">交差点内物標情報</span>
              <v-spacer />
              <v-btn color="blue-grey" dark v-on:click="onClickTableSet" height="4vh">
                <v-icon>mdi-table-settings</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider />
            <LocationInfoTable ref="location_table" :locationTable="locationTable" :selectSensor="selectSensor" :itemsPerPage="3" v-on:selectObject="selectObject" />
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card id="realtime_led" elevation="0" outlined max-height="30vh">
            <v-card-title class="py-0" style="font-size: 22px; font-weight: bold; color: white; background-color: #0041c0; height: 4vh">
              <span class="py-0">LED表示板</span>
              <v-spacer />
            </v-card-title>
            <LedInfoTable ref="led_info_table" :selectSensor="selectSensor" :ledSensorList="ledSensorList" mode="realTime" />
            <v-divider />
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-main>
      <router-view />
    </v-main>
    <Title :titleInfo="titleInfo" />
    <Loading v-show="isLoading" />
    <CommonDialog :dialog="dialog" />
  </div>
</template>

<script>
  import Title from "@/components/Title";
  import Loading from "@/components/Loading";
  import CommonDialog from "@/components/CommonDialog";
  import LocationInfoTable from "@/components/LocationInfoTable";
  import VirtualMap from "@/components/VirtualMap";
  import LedInfoTable from "@/components/LedInfoTable";
  import RealTimeVideo from "@/components/RealTimeVideo";
  import SelectSensor from "@/components/SelectSensor";
  import { commonFunction, timerFunction, communicationFunction, mapFunction } from "@/mixins/utils";

  export default {
    name: "RealTimeView",
    components: {
      Title,
      Loading,
      CommonDialog,
      LocationInfoTable,
      VirtualMap,
      LedInfoTable,
      RealTimeVideo,
      SelectSensor
    },
    mixins: [commonFunction, timerFunction, communicationFunction, mapFunction],
    data: function() {
      return {
        setting: undefined,
        address: undefined,
        titleInfo: {
          title: undefined,
          show: {
            multicamera: true,
            virtual: true,
            menu: true
          },
          menuList: undefined
        },
        dialog: {
          isDialog: false,
          title: "",
          message: ""
        },
        isLoading: false,
        poleId: undefined,
        locationTable: undefined,
        cameraList: [],
        selectCamera: undefined,
        sensorList: [],
        selectSensor: [],
        selectSensorTemp: [],
        poleInfo: undefined,
        showCamera: false,
        isDisplayLEDBlock: false,
        ledSensorList: [],
        vMapEnabled: false
      };
    },
    created() {
      this.initialize();
    },
    mounted() {},
    beforeDestroy() {
      this.stopPosListWithPoleUpdateTimer();
    },
    methods: {
      async initialize() {
        this.setting = this.getSetting();
        this.address = this.$store.state.selectPole.address;
        this.titleInfo.title = this.$store.state.selectPole.address + " リアルタイムモニター";
        this.titleInfo.menuList = this.setMenuList(this.setting.menu.realtime);
        this.poleId = this.$store.state.selectPole.poleId;
        this.locationTable = this.setting.locationInfoTableType1;

        this.initSensorList(this.poleId);
      },
      async initSensorList(poleId) {
        this.isLoading = true;
        let promise = this.updatePoleListAsPoleId(poleId, undefined);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.vMapEnabled = true;
            this.sensorList = this.getSensorSettingList();
            if (this.sensorList.length != 0) {
              for (const item of this.sensorList) {
                for (const itemData of item.children) {
                  this.selectSensorTemp.push(itemData.id);
                  let obj = JSON.parse(itemData.id);
                  if (obj.kind == "表示板") {
                    this.ledSensorList.push(obj);
                  }
                }
              }
            }
            this.poleInfo = JSON.parse(JSON.stringify(this.$store.state.selectPole));
            this.initCameraList();
            this.startPosListWithPoleUpdateTimer(this.poleId, this.setting.common.posUpdateIntervalReal, this.timeoutPosListRealTimer);
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.showErrorDialig(this.str.dialog.title.getError, this.str.dialog.message.getErrorSensorInfo);
          });
      },
      async initCameraList() {
        this.cameraList = this.getCameraList();
        if (this.cameraList.length != 0) {
          this.selectCamera = this.cameraList[0];
          this.showCamera = true;
        }
      },
      async timeoutPosListRealTimer(poleId) {
        let sensorIds = this.$store.state.selectPole.sensorList.map(obj => obj.sensorId);
        let promise = this.updatePosListReal(poleId, sensorIds);
        promise
          .then(() => {
            this.setSelectPole(poleId, this.address);
            this.updateSensorList(this.poleInfo, this.$store.state.selectPole, this.updatePoleEvent, this.updateSensorEvent);
            let newCameraList = this.getCameraList();
            this.updateCameraList(this.cameraList, newCameraList, this.updateCameraEvent);
            this.$refs.virtual_map.updatePos();
            this.$refs.location_table.update();
            this.$refs.led_info_table.update();
          })
          .catch(error => {
            if (error == this.errcode.noContent) {
              this.setSelectPole(poleId, this.address);
              this.updateSensorList(this.poleInfo, this.$store.state.selectPole, this.updatePoleEvent, this.updateSensorEvent);
              let newCameraList = this.getCameraList();
              this.updateCameraList(this.cameraList, newCameraList, this.updateCameraEvent);
              this.$refs.virtual_map.updatePos();
              this.$refs.location_table.update();
              this.$refs.led_info_table.update();
            }
          });
      },
      showErrorDialig(title, message) {
        this.dialog.title = title;
        this.dialog.message = message;
        this.dialog.isDialog = true;
      },
      updatePoleEvent() {
        this.$refs.virtual_map.updatePole();
        this.poleInfo.latlng = JSON.parse(JSON.stringify(this.$store.state.selectPole.latlng));
      },
      updateSensorEvent() {
        this.sensorList = this.getSensorSettingList();
        this.selectSensor = this.updateSelectSensor(this.selectSensor, this.sensorList);
        this.selectSensorTemp = [];
        for (const item of this.sensorList) {
          for (const itemData of item.children) {
            this.selectSensorTemp.push(itemData.id);
          }
        }
        this.$refs.virtual_map.updateSensor();
        this.poleInfo.sensorList = JSON.parse(JSON.stringify(this.$store.state.selectPole.sensorList));
      },
      updateCameraEvent() {
        this.cameraList = this.getCameraList();
        let hit = false;
        this.cameraList.forEach(camera => {
          if (camera.sensorId == this.selectCamera.sensorId) {
            hit = true;
            return;
          }
        });
        if (!hit) {
          this.selectCamera = undefined;
        }
      },
      async onClickTableSet() {
        await this.$refs.location_table.columSelectModal();
      },
      selectObject(latlng) {
        this.$refs.virtual_map.setCenter(latlng);
      },
      setUpSelectSensor(value) {
        this.selectSensor = [];
        if (value.length != 0) {
          for (const item of value) {
            let obj = JSON.parse(item);
            this.selectSensor.push(obj);
          }
        }
      }
    }
  };
</script>

<style>
  #realtime_camera {
    height: 65vh;
    background-color: rgb(56, 54, 54);
  }

  #realtime_location {
    height: 30vh;
  }

  #realtime_vmap {
    height: 90vh;
    width: 100%;
  }

  #realtime_logo {
    background-color: #353535;
    height: 42vh;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    opacity: 0.6;
  }
</style>
